.signup-main-container {
  display: flex;
  align-content: center;
  width: 100vw;
  height: 80vh;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 0 10vw;

  input, button {
    margin: 5px;
    width: 100%;
  }
}

.submitted-form {
  max-width: 60vw;

  h2 {
    line-height: 1.6;
  }
}