


html {
  scroll-behavior: smooth;
}
.arrow-icon {
  color: #000;
  transition: all 200ms ease;

  &:hover {
    color: #0e1930;
    transform: scale(1.1);
  }
}

a {
  color: var(--primary-blue);
  transition: all 200ms ease !important;
  &:hover {
    text-decoration: none !important;
  }
}

.terms-wrapper {
  margin: 2vh auto;

  h2, h3 {
    text-align: center;
    margin: 5vh;
  }

  a {
    color: blue;
  }
}

section {
  transition: all 200ms ease;
}

form.button-right {
  display: flex;
  flex-direction: column;
  > div:last-child, > div:last-child div {
    display: flex;
    justify-content: flex-end;
  }
}

input, textarea {
  border-radius: 20px !important;
  border-color: var(--primary-blue) !important;
  border-width: 3px !important;
  margin-top: .5em;
  margin-bottom: .5em;

  &:focus {
    border-color: var(--primary-green) !important;
    box-shadow: none !important;
  }
}

.btn-link-danger {
  color: #DC3545 !important;
}
.btn {
  &:focus {
    // background: initial !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
.btn-secondary {
  &:focus {
    background: var(--primary-green) !important;
  }
}
.btn-primary {
  &:focus {
    background: var(--primary-blue) !important;
  }
}

.small-int-select {
  max-width: 90px;
  min-width: 90px !important;
}

.line-height {
  line-height: 1.2em;
}

.btn-xs {
  font-size: 14px;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
}

.flex-row.space-between {
  justify-content: space-between !important;
}

.ui-select {
  border: none;
  border-radius: 20px;
  background: url('../photos/caret-gray-down.svg') no-repeat calc(100% - 12px)/24px, linear-gradient(
    to right,
    rgba(234,244,250, 100),
    rgba(236,247,237, 100)
  );
  padding: .25rem 1em;
}

.uppercase {
  text-transform: uppercase;
}

.password-input {
  position: relative;

  img {
    position: absolute;
    top: 14px;
    right: 1.5em;
    opacity: .5;
    transition: all 200ms ease;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.form-control.is-invalid {
  background: var(--primary-gradient-light);
  border: solid #dc3545 3px !important;
}

.smaller-text {
  font-size: .8em;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.privacy-wrapper {
  margin: 2vh auto;

  h2, h3 {
    text-align: center;
    margin: 5vh;
  }

  a {
    color: blue;
  }

  .left-margin {
    margin-left: 5vw;
  }
}

.gray-color {
  color: var(--primary-gray) !important;
}

.blue-color {
  color: var(--primary-blue) !important;
}

.green-color {
  color: var(--primary-green) !important;
}

.white-color {
  color: #ffffff;
}


.scrolling-carousel {
  .col {
    display: flex;
    min-height: 146px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .carousel-indicators, .carousel-control-next {
    // display: none !important;
    transform: translateY(2em);
  }

  .carousel-inner {
    display: flex;
    align-items: center;
  }

  a {
    transition: unset !important;
  }

  .carousel-item {
    // transition: transform 5s linear;
    img {
      padding: 0 1em;
      filter: grayscale(1);
      // transition: all 200ms ease;
      &:hover {
        filter: grayscale(0);
      }
    }
  }

}

.carousel {
  width: 100%;

  &.slide {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .carousel-indicators {
      margin: 0;
      right: unset;
      left: unset;
      position: relative;
      order: 2;

    }

    .carousel-inner {
      order: 1;
      margin: 1em 0 0 0;
    }

    .carousel-control-prev {
      display: none;
    }

    .carousel-control-next {
      opacity: 1;
      display: block;
      position: relative;
      right: unset;
      bottom: unset;
      top: unset;
      height: 30px;
      width: 30px;
      order: 3;
      margin-top: 4px;
      margin-left: .45em;
      transition: all 200ms ease;

      &:hover {
        padding-left:2px;
      }
    }

  }

  $dotSize: 20px;
  .carousel-indicators li{
    max-height: $dotSize;
    max-width: $dotSize;
    height: $dotSize;
    width: $dotSize;
    padding: 0px;
    border-radius: 100%;
    border: none;
    background: var(--primary-gradient);
    opacity: 1;
    margin: 0 .35em;
  }
  .carousel-indicators li.active {
    width: $dotSize;
    max-width: $dotSize;
    position: relative;
    box-sizing: border-box;

    $border: 4px;
    color: #FFF;
    background: #ecf5f4;
    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */
    border-radius: 1em;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: var(--primary-gradient);
    }
  }

  &.on-white {
    .carousel-indicators li.active {
      background-color: #ffffff !important;
    }
  }

}

section {
  padding: 5rem 6%;
}

.row {
  width: 100%;
  align-items: center;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

section.light-grad-convex {
  background: url('../photos/inverse-top.png'), url('../photos/inverse-bottom.png'), var(--primary-gradient-light);
  background-position: 0% 0%, 0% 100.05%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 500px;
  padding: 8rem 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

section.light-grad-bulge {
  background: url('../photos/bulge-top.png'), url('../photos/bulge-bottom.png'), var(--primary-gradient-light);
  background-position: 0% 0%, 0% 100.05%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 500px;
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

section.light-grad-convex.bg-grad-inverse {
  background: url('../photos/inverse-top.png'), url('../photos/inverse-bottom.png'), var(--primary-gradient-reverse-light);
  background-position: 0% 0%, 0% 100.05%;
  background-size: 100%;
  background-repeat: no-repeat;
    
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

section.light-grad-bulge.bg-grad-inverse {
  background: url('../photos/bulge-top.png'), url('../photos/bulge-bottom.png'), var(--primary-gradient-reverse-light);
  background-position: 0% 0%, 0% 100.05%;
  background-size: 100%;
  background-repeat: no-repeat;
    
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

section.body-top-wrapper {
  background: url('../photos/bulge-bottom.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: -40px !important;
  position: relative;
  z-index:3;
  padding-top: 7em;
  padding-bottom: 0em;
}

.centered-text {
  text-align: center;
  a, button, p, h1, h2, h3, h4, h5, h6, div {
    text-align: center;
  }
}

.centered-row {
  justify-content: center;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.on-top {
  position: relative;
  z-index: 4;
}

.title-icon {
  display: flex;
  align-items: center;
  h2, h3 {
    margin: 0;
  }
  img:first-child {
    margin-right: 1em;
  }
  img:last-child {
    margin-left: 1em;
  }

  &.center-title {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

}

.title-icon-stacked {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // justify-content: flex-end;

  h2 {
    margin: .5em 0;
    text-align: right;
  }
}

.cta-centered {
  text-align: center;

  h3, h2 {
    margin-bottom: -3px;
  }
  hr {
    margin-bottom: 1.5em;
  }
}

.container-fluid {
  padding: 0;
}

.thin-container {
  max-width: 900px;
}

.form-modal-wrapper {
  form {
    padding: 2em 0;
  }
}

.modal-backdrop {
  background-color: #2f2e41;
}

.modal-backdrop.show {
  opacity: .75;
}

.modal-content, .modal-dialog {
  position: unset !important;
}

.modal-header {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  top: 20px;
  right: 40px;
  button {
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 200ms ease;
    opacity: 1 !important;
    &:hover {
      transform: rotate(90deg);
    }
    span {
      color: #ffffff;
      font-size: 2em;
      opacity: 1 !important;
    }
  }

}

.faq-wrapper {
  .accordion {
    margin-top: 3em;
  }
  .card {
    background: none;
    border: none;
    text-align: center;
  }
  .card-header {
    border-radius: 100px !important;
    background: var(--primary-gradient-reverse-semi-light);
    border: none;
    margin: 1em 0;
    color: var(--primary-gray);
    cursor: pointer;
    position: relative;
    &:after {
      content:'';
      display: block;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translate(0%, -50%);
      width: 20px;
      height: 20px;
      background: url('../photos/icons/plus.svg');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 300;
    }
  }
  .card-body {
    color: var(--primary-gray);
    line-height: 1.5em;
    padding: 1em 3em;
  }
  .active-faq {
    background: rgba(0,0,0,0);
    border: solid 4px var(--primary-gray);
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 600;
    font-size: 1.1em;
    pointer-events: none;

    &:after {
      content:'';
      display: block;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translate(0%, -50%);
      width: 30px;
      height: 30px;
      background: url('../photos/icons/minus.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 300;
    }
  }
  ul {

    text-align: left;
    
    list-style-type: initial;

  }

}

.main-title-h2 h2 {
  font-size: 45px !important;
  text-align: center;
}

.main-title-h1 h1 {
  font-size: 38px !important;
  text-align: center;
}

.muted {
  opacity: .4;
}

[disabled] {
  span, img {
    opacity: .4;
    cursor: default;
  }
}
.reverse {
  transform: rotate(180deg);
}

.popover {
  border: none;
  > div:first-child {
    &:before {
      border: none;
    }
    &:after {
      border-right-color: var(--primary-gray);
    }
  }

  > div:first-child {
    display: none;
  }

  > div:last-child {
    background: var(--primary-gray);
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 1.5em 2em;
  }
}

.white-link a, .white-link {
  font-weight: 600;
  color: #ffffff !important;
}




.holiday-wrapper {
  position: relative;
  max-width: 900px;
  border-radius: 1em;
  background-color: #fff;
}

.holiday-script {
  width: 450px;
  max-width: 80%;
}

.holiday-rule {
  width: 90px;
  height: 4px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#46b34a), to(#2993d0));
  background-image: linear-gradient(180deg, #46b34a, #2993d0);
}


.holiday-heading {
  margin-bottom: 15px;
  color: #2993d0;
  font-size: 30px;
  margin-top: 20px;
  line-height: 30px;
}

.holiday-body {
  color: #6c7c85;
  font-size: 18px;
  line-height: 22px;

  a {
    color: #6c7c85;
    font-weight: 500;
  }

  a:hover {
    color: black;
  }
}


.holiday-italic {
  margin-top: 5px;
  color: #6c7c85;
  font-size: 21px;
  font-style: italic;
  font-weight: 400;
}

.holiday-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 26px;
  border-radius: 40px;
  background-color: #da4a6b;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
}

.holiday-button:hover {
  background-color: #b83c58;
  color: #ffffff;
}


.holiday-gift {
  width: 100%;
  max-width: 300px;
}

.x-line {
  width: 40px;
  height: 3px;
  border-radius: 12px;
  background-color: #6c7c85;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.x-line.bottom {
  -webkit-transform: translate(0px, -3px) rotate(-45deg);
  -ms-transform: translate(0px, -3px) rotate(-45deg);
  transform: translate(0px, -3px) rotate(-45deg);
}

.close-link {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: all 200ms ease;
}

.close-link:hover {
  transform: rotate(90deg);
}

@media screen and (max-width: 767px) {
  .x-line {
    width: 30px;
  }

  .close-link {
    top: 2px;
    right: 0px;
  }
}

@media screen and (max-width: 479px) {
  .x-line {
    width: 20px;
  }

  .close-link {
    top: 0px;
  }
}



@media screen and (max-width: 991px) {
  .holiday-content {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }

  .holiday-gift {
    width: 100%;
    max-width: 150px;
    margin-left: -2.3em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
  }
}


// Small Laptop

@media (max-width: 1400px) and (min-width: 1200px) {
  .modal-xl {
    max-width: 900px;
  }
}



// Tablet

@media (max-width: 1224px) {

  section.body-top-wrapper {
    margin-top: -20px !important;
  }


}




// Mobile 

@media (max-width: 767px) {

  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 30px;
    line-height: 1em;
  }

  h3 {
    font-size: 20px;
  }

  section {
    padding: 4em 1em;
  }

  section.light-grad-convex, section.light-grad-bulge {
    padding: 4em 1em;
  }

  .video-section-wrapper {

    .title-icon-stacked {
      text-align: center;
      align-items: center;
      padding: 0 0 1em 2em;
  
    }
  }

  .modal-header {
    top: 10px;
    right: 10px;
    button {
      top: 10px;
      right: 10px;
    }
  }

  .scrolling-carousel {
    // width: 400px !important;
    // transform: scale(1.2);
    // margin-top: 2em;
    // padding: 0 30px;

    .carousel-inner {
      transform: scale(1.2);
      bottom: -20px;
    }
    .carousel-control-next, .carousel-indicators {
      display: none !important;
    }

    .col {
      padding: 0px;
    }
  }

  section.body-top-wrapper {
    margin-top: -10px !important;
    padding-top: 5em;
  }

  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .title-icon, .title-icon-stacked {
    flex-direction: column;
    img {
      order: -1;
      margin: 0px;
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: .5em;
    }
    h1, h2, h3 {
      text-align: center !important;
      margin: 0px;
    }

  }


  .title-icon.stack-mobile {
    flex-direction: column;
    align-items: center;
    img {
      order: 1;
      margin-bottom: 1em;
    }

    h2 {
      order: 2;
      text-align: center;
    }
  }
  .btn {
    line-height: 1em;
  }


}




