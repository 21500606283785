#solution-background {
    height: 50vh;
    background-image: url('../photos/solutions/sol_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
}

#solution-background div.row {
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

#solution-background div.col {
    text-align: center;
    color: white;
}


#solution-lite img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}

#solution-lite div.row{
    padding: 5%;
    background-image: url('../photos/solutions/solution-lite-transition.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat; 
}


#solution-mobile img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}

#solution-mobile div.row{
    padding: 5%;
    /* background-image: url('../photos/solutions/trans-2.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;  */
    background-color: rgba(232, 248, 255, 1);
}



#solution-desktop img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}

#solution-desktop div.row{
    padding: 5%;
    background-image: url('../photos/solutions/solution-desktop-transition.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; 
}


@media only screen and (max-width: 768px) {
    #solution-mobile div.row{
        flex-direction: column-reverse;
    }
}

.solutions-wrapper {
    
    svg {
        width: 24px;
        height: 24px;
    }

    button {
        margin-bottom: 20px;
    }
}