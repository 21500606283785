#testimonial-home {
    background-color: white;
    color: black;
    text-align: center;
    overflow: hidden;
    padding-top: 20px;
    
    hr {
        margin: 0 auto 20px auto;
        max-width: 50px;
    }
}

#testimonial-home {

    .row {
        justify-content: center;
        align-items: center;
    }

    #testimonial_main {
        max-width: 90%;
        min-width: 350px;
        margin-bottom: 5%;

        .card {
            border: none;
        }
        .row:first-of-type {
            justify-content: flex-start;
        }
        .closing-quote {
            text-align: right;
        }
        .testimonial-button-wrapper {
            margin: 10px 0;
        }
    }

    .arrows-wrapper {
        overflow: hidden;
        max-width: 100vw;

        .col:first-child {
            position: absolute;
            text-align: left;
            max-width: 30%;
            transform: translateY(200px) translateX(-34vw);
            z-index: 3;
        }
        
        .col:last-child {
            position: absolute;
            text-align: right;
            max-width: 30%;
            transform: translateY(200px) translateX(34vw);
            z-index: 2;
        }

    }

    .header.row{
        min-height: 15vh;
    }

    .body.row{
        min-height: 75vh;
        color: white;
    }
}
#testimonial_left {
    align-items: center;
}