#herocarousel {
    height: 90vh;
    padding-bottom: 10vh;
    align-items: center;
    overflow: visible;
    overflow-x: hidden;

    .col {
      align-items: center;
    }

    .carousel-inner{
      height: 40vh;
      padding-top: 13vh;
      overflow: visible;
    }

    .carousel-indicators{
      position: absolute;
      left: 0%;
      bottom: -60px;
      /* top: 150%; */
    }
}

.carousel h1{
    text-align: center;
    color: white;
    cursor: pointer;
}

.carousel-caption{
    position: static;
}

.carousel-item h1{
  /* align-items: center; */
  /* padding-top: 6%; */
  /* top: 50%; */
  width: 75%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {

  #herocarousel .carousel-indicators{
    bottom: -100px;
  }

  #herocarousel .carousel-inner h1{
    /* max-width: 80%;
    text-align: center;
    justify-content: center; */
  }

  #herocarousel {
    height: 55vh;
  }
  
}


/* .video-background {
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
  background-color: black;
  margin: 0%;
  padding: 0%;
} */

.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
  overflow: hidden;
}
.video-foreground,
.video-background video {
  position: fixed; 
  right: 0;
  bottom: 0;
  z-index: -99;          

  min-width: 100%;      
  min-height: 100%;
  width: auto;          
  height: auto;

  top: 50%;             
  left: 50%;            

  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);

  /* background: url(bkg.jpg) no-repeat;     */
  background-size: cover;
  overflow: hidden;
}

/* @media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
} */

@media only screen and (max-width: 600px) {
  
  .video-foreground,
  .video-background video {
    /* left: -15vw; */
  }
}