.scrolling-wrapper {


  // .container1 {
  //   width: 80%;
  //   margin: 20px auto;
  // }
  // .carousel1 {
  //   background: #fff;
  //   width: 100%;
  //   height: 280px;
  //   display: flex;
  //   justify-content: flex-start;
  //   overflow: hidden;
  
  //   position: relative;
  // }
  // .slider1 {
  //   display: flex;
  //   height: 100%;
  //   // width: 500%;
  //   flex-shrink: 0;
  //   transition: all 3s linear;
  
  // }
  // section {
  //   flex-basis: 20%;
  //   flex-shrink: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  // .controls1 button.next1 {
  //   opacity:0;
  //   position: absolute;
  //   right: 20px;
  //   top: 20px;
  //   background: none;
  //   border: none;
  //   cursor: pointer;
  //   outline: none;
  //   color: #c058dc;
  // }
  // .controls1 button.prev1 {
  //   opacity:0;
  //   position: absolute;
  //   left: 20px;
  //   top: 20px;
  //   background: none;
  //   border: none;
  //   cursor: pointer;
  //   outline: none;
  //   color: #c058dc;
  // }
  // .controls1 button i {
  //   opacity:0;
  //   font-size: 50px;
  // }




  width: 100%;
  overflow: hidden;
  position: relative;
  
  > div {
    display: flex;
    align-items: center;
    position: relative;
    transform: translateZ(0);
  }

  .scroll-now {
    transform: translateX(-200%) translateZ(0);
  }

  .active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%) !important;
  }

  .active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%) !important;
  }


  .carousel-item {
    // transition: transform 1000ms linear;
  }

  img {
    // height: 60px;
    max-width: 220px;
    max-height: 88px;
    // -webkit-transform: translateZ(0); 
  }

  a {
    // float: left;
    display: inline-block;
    position: relative;
    filter: grayscale(1);
    transition: filter 200ms ease;

    &:hover {
      filter: grayscale(0);
    }

    margin-right: 4em;
  }
}


// Tablet Styles

@media (max-width: 1224px) {

  .scrolling-wrapper {
    margin-top: 2em;
  }


}
