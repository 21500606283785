#footer-container {
    padding: 2% 2%;
    background: var(--primary-gradient-footer);
    background: url('../photos/bulge-top.svg'), var(--primary-gradient-footer);
    background-position: 0% 0%;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 8rem 5em 3.5rem 5em;
    overflow: hidden;

    a:hover {
        text-decoration: none;
    }

    form button {
        margin-top: 15px;
    }

    .footer-row {
        display: flex;
        align-items: flex-start;

        > div:last-child {
            display: flex;
            justify-content: flex-end;

            .company-logo {
                height: 250px;
                width: auto;
                max-width: 100%;
            }
        }

        .col {
            display: flex;
            flex-direction: column;

            a {
                color: #515e64;
                font-weight: 500;
                margin: .35em 0;
                transition: all 200ms ease;

                &:hover {
                    color: #ffffff;
                }
            }

            .col {
                padding: 0;
            }

            .col div {
                margin: .35em 0;
            }
            
            h3 {
                margin-bottom: 15px;
            }
        }
    }

    .footer-footer-wrapper {
        flex-direction: column;
        align-items: flex-start;

        hr {
            width: 300px;
            margin: 0;
            margin-bottom: 1em;
        }
        a {
            color: var(--primary-blue);
            transition: all 200ms ease;
            &:hover {
                color: #ffffff;    
            }
        }

    }
}
.confirm-signup > h3 {
    color: green;
    text-align: center;
    font-size: 200%;
    line-height: 1.2em;
}

.already-subscribed > h3 {
    color: green;
}

.failed-subscribe > h3 {
    color: red;
}

#copyright {
    text-align: left;
    background-color: #1A2633;
    color: white;

    div.col > div {
        text-align: center;
        padding: 2vh 0;
    }
}

.cookieConsent {
    background: var(--primary-gradient-light) !important;
    padding: .65em 2em;
    
    p {
        color: var(--primary-gray) !important;
        margin: 0px;
    }
    
    a {
        color: var(--primary-blue);
    }

    button {
        color: #ffffff !important;
        background: var(--primary-green) !important;
        border-radius: 30px !important;
        padding: .45em 1em !important;
        transition: all 200ms ease;

        &:hover {
            filter: contrast(1.4);
        }
        // font-size: 13px;
    }
}


// Tablet Styles

@media (max-width: 1224px) {

    #footer-container {
        background-position: 0% -1%;
        padding: 6em 3em 3em 3em !important;

        .row {
            margin: 0 !important;
        }
        .footer-footer-wrapper {
            a {
                color: #ffffff;
            }
            p {
                font-size: .8em;
            }
        }

        .footer-row {
            margin: 0;
            min-height: 500px;

            .col:nth-child(1) {
                order: 2;
                padding-left: 0px;
            }
            .col:nth-child(2) {
                order: 3;
            }
            .col:nth-child(3) {
                order: 4;
                padding-right: 0px;
            }
            .col-lg-3.col-md-3 {
                order: 1;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                display: flex !important;
                justify-content: center !important;
                margin-bottom: 0em;
                img {
                    height: 150px !important;
                }
            }
        }
    }



}






// Mobile styles

@media (max-width: 767px) {

    #footer-container, #footer-container > div {
        max-height: unset !important;
        height: unset !important;
        // position: static !important;
        // display: block !important;
    }

    #footer-container {
        background-position: 0% 0%;
        padding: 4em 2em 2em 2em !important;

        .footer-row {
            flex-direction: column;
            min-height: 900px;
        }

        .col-lg-3.col-md-3 {
            margin-bottom: 3em !important;
        }



        .footer-footer-wrapper {
            display: block !important;

            a {
                color: #ffffff;
            }
            p {
                font-size: .6em;
                text-align: center;
            }
        }

        .col {
            padding: 0px;
            text-align: center;
            padding-bottom: 1.5em;
        }

    }


}