.nucca-wrapper {
  padding: 5em 0;

  a {
    color: #007BFE;
  }

  #hubspotForm {
    padding-top: 3em;
  }
}

.nucca-body-wrapper {
  .row > div:last-child {
    margin-left: 1.5em;
    h2 {
      margin-bottom: 0px;
    }
    h3 {
      margin-bottom: 1em;
    }
  }
}

.nucca-benefits-wrapper {
  padding-top: 2em !important;
}

.nucca-details-wrapper {
  h2.blue-color {
    margin-bottom: 0em;
    margin-top: 1em;
  }
  h2.green-color {
    margin-bottom: 1em;
  }
  margin: 2em auto 4em;
  .row {
    display: flex;
    align-items: flex-start;
  }
  li {
    margin: 2em 0;
  }
  ol {
    color: var(--primary-blue);
    font-weight: 600;
    font-size: 1.1em;

  }
}
.nucca-benefits-wrapper {
  // background: url('../photos/bulge-bottom.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  // margin-top: -40px !important;
  position: relative;
  z-index:3;
  padding-top: 7em !important;
  padding-bottom: 0em;

  h3, img, p {
    text-align: center;
  }

  .row {
    padding: 2em;
  }

  .col {
    padding: 1.5em;
    transition: all 400ms ease;
    border-radius: 30px;
    text-align: center;

    &:hover {
      background: rgba(108,125,133,.05);
      transform: scale(1.02);
    }
  }
}

.nucca-form-wrapper {
  padding-top: 0px;
  .row {
    display: flex;
    justify-content: center;
  }

  form {
    margin-top: 1em;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  input:not(.form-check-input) {
    margin: 0px;
    background: rgba(0,0,0,0);
  }

  .form-check-label{
    color: var(--primary-blue);
    font-size: .9em;
  }

  .form-check {
    margin-left: 1em;
  }

  button {
    margin-top: 1em;
  }

  .form-footer-wrapper {
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
  }
}

.standalone-video-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .video-play-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      // transform: translate(-50%, -50%) scale(1.03);
      width: 160px;
      height: 160px;
    }
  }

}



// Tablet

@media(max-width: 56em) {


  .standalone-video-wrapper {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 3em;
    .video-play-icon {
      width: 70px !important;
    }
  }

  .nucca-body-wrapper {
    .container {
      > .row > div:last-child {
        margin-left: 0px;
        h2, h3, p {
          text-align: center;
        }
      }
    }
  }




}





// mobile

@media (max-width: 37.5em) {



  h1 {
    line-height: 1.1em;
  }
  .nucca-wrapper {
    padding: 4em 1em;
  }

  .nucca-form-wrapper {
    padding-top: 0px;
    .row {
      display: flex;
      justify-content: center;
    }
  
    form {
      margin-top: 1em;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  
    input:not(.form-check-input) {
      margin: 0px;
      background: rgba(0,0,0,0);
    }
  
    .form-footer-wrapper {
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
    }
  }




}