#product-showcase {
    padding-top: 20px;
    hr {
        margin: 0 auto 20px auto;
        max-width: 50px;

    }
}

#carousel-mobile-solution {
    margin-top: 20px;

    img {
        height: 100%;
    }
}

#carousel-desktop-solution .carousel h1{
    color: var(--secondary-color);
}

#carousel-desktop-solution .carousel-indicators > li{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.8;
}

#carousel-desktop-solution li.active{
    background-color: #3D7F45;
}

#carousel-desktop-solution div.row{
    justify-content: center;
}

#carousel-desktop-solution .carousel.slide.carousel-fade{
    margin-left: auto;
    margin-right: auto;
}

#carousel-desktop-solution .carousel-item.active, .carousel-item {
    text-align: center;
}

#carousel-desktop-solution img{
    height: 60%; 
    width: auto;
}

#product-showcase {
    min-height: 90vh;
    text-align: center;
    justify-content: center;
    background: var(--background-color-linear-gradient);
    color: black;
}

#product-showcase .row{
    align-items: stretch;
    align-items: center;
    justify-content: center;
}

#product-showcase .col{
    justify-self: center;
    align-self: center;
    text-align: center;
}

#product-showcase .header.row{
    min-height: 15vh;
}

#product-showcase .body.row{
    min-height: 75vh;
}

#product-showcase img{
    width: 75%;
    height: auto;
    align-self: center;
    justify-self: center;
}

#carousel-desktop-solution-mobile {
    
    min-height: 100vh;
    div.row{
        padding: 1%;    
    }
}
#carousel-desktop-solution-mobile #product-showcase {
}

#carousel-desktop-solution.query-mobile {
   img {
       width: 55%;
   } 
}