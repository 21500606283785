.consumer-hero-wrapper {
  transition: all 200ms ease;
  padding-top: 2em;
  .container {
    padding: 0em 0 5em 0;
  }

  .jumbotron {
    background-color: rgba(0,0,0,0);
    position: relative;
    z-index: 1;
    margin-bottom: 0px;
    padding-bottom: 1em;

    h1 { 
      color: var(--primary-blue);

      span {
        color: var(--primary-green);
      }
    }

    footer {
      display: flex;
      align-items: center;

      p {
        color: var(--primary-green);
        padding: 0 20px;
        display: block;
        margin: 0px;
      }

      .btn-primary {
        background: var(--primary-blue);
        border: solid 4px var(--primary-blue);

        &:hover {
          filter: contrast(1.4);
        }
      }
     .btn-secondary {
        background: #ffffff;
        position: relative;
        color: var(--primary-green);
        border: none;
        background-clip: padding-box;
        transition: all 200ms ease;
        font-weight: 500;
        // border: solid 4px transparent !important;
        &:hover{
         background: var(--primary-gradient);
         color: #ffffff;
        }

        &:before {
          content: "";
          display:block;
          z-index: -1;
          position: absolute;
          background: var(--primary-gradient);
          top:0;
          right:0;
          bottom:0;
          left:0;
          margin: -4px;
          border-radius: 50px;
        }
      }
    }
    img {
      transition: all 200ms ease;
      &:hover {
        transform: scale(1.02);
        filter: contrast(1.8);
      }
    }
  }

  .animation-wrapper {
    height: 400px;
    width: 400px;
    position: relative;

    img {
      // width: 30px;
      margin: 0;
    }

    #phone-wrapper {
      .hero-background {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      .girl, .girl-top {
        width: 180px;
        position: absolute;
        top: 20%;
        left: 45%;
      }

      .girl-top {
        opacity: 0 !important;
        cursor: pointer;
        width: 80px;
        height: 100px;
        left: 56%;
        top: 42%;
      }

      .mobile-device {
        position: absolute;
        transition: all 300ms ease-out;
        
        img {
          display: block;
          position: absolute;
          width: 260px;
          height: auto;
          cursor: move;
        }
        .device-button-wrapper {
          position: absolute;
          top: 170px;
          left: 170px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;

          button {
            width: 100%;
            transform: scale(.7);

            &:focus {
              box-shadow: none;
              background: var(--primary-green);
            }
          }

          > button:first-child {
            margin-bottom: 0em;
          }

          .ipad-btn-blue {
            background: var(--primary-blue) !important;
          }
  
          &.hide {
            display: none;
          }
        }


      }

      .indicator {
        position: absolute;
        width: 70px;
        left: 16%;
        top: 44%;
        opacity: 1;
        transition: all 200ms ease;
      }


    }
  }

}

.why-carousel {
  .row {
    padding: 2em;
  }
  .col {
    padding: 1.5em;
    transition: all 400ms ease;
    border-radius: 30px;

    &:hover {
      background: rgba(108,125,133,.05);
      transform: scale(1.02);
    }
  }
}

.how-wrapper {
  .container div:nth-child(2) article, .container article {
    position: relative;
    &:before {
      content: '1.';
      font-size: 300px;
      font-weight: 500;
      opacity: .07;
      display: block;
      position: absolute;
      // top: 0px; right: 0px; bottom: 0px; left: 0px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  .container div:nth-child(3) div:nth-child(1) {
    display: flex;
    justify-content: flex-end;

    article:before {
      content: '2';
    }
  }
  .container div:nth-child(4) div:nth-child(2) {

    article:before {
      content: '3';
    }
  }

  div article:nth-child(3) {
    &:before {
      content: '3';
    }
  }

  div img {
    transform: scale(1.25);
    z-index: -1;
    position: relative;
  }
}

.video-section-wrapper {
  align-items: flex-start;

  .title-icon-stacked {
    margin: 4em 4em 0 0;
  }

  .consumer-video-wrapper {
    background: url('../photos/consumer-landing/video-background-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .video-play-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 200ms ease;
      cursor: pointer;

      &:hover {
        // transform: translate(-50%, -50%) scale(1.03);
        width: 160px;
        height: 160px;
      }
    }

  }
}

.features-wrapper {
  margin-top: -7em;
  padding-top: 12em !important;

  .row {
    align-items: flex-start;
  }

  .row:last-child {
    margin-top: 3em;
  }

  .title-icon {
    margin-bottom: 1em;
    img {
      margin-right: .75em;
      margin-left: 0px;
    }
  }
}

.consumer-landing-cta {
  background: url('../photos/grad-rule-convex.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 5em 0 9em 0;

  img {
    transition: all 200ms ease;
    &:hover {
      transform: scale(1.02);
      filter: contrast(1.8);
    }
  }
}

.for-professionals-wrapper {
  padding: 5em 0;

  .col:last-child {
    padding-left: 3em;
  }
}

.infographic-wrapper {
  padding: 3em 0;

  .col:first-child {
    padding-right: 3em;
  }
  input {
    border-radius: 100px;
    border: solid 4px var(--primary-blue);
    margin-right: 1.5em;
    margin-left: -5px;
    transform: scale(.96);
    &:focus {
      border-color: var(--primary-green);
      transform: scale(.96);
      box-shadow: none;
    }
  }
}

.mailchimp-form-wrapper {
  padding: 2em 5em;

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding-top: .5em;
    position: relative;

    input {
      width: 48%;
      border-color: var(--primary-blue) !important;
      // border-image: unset !important;
      // background-color: unset !important;
      border-style: solid !important;
      padding: .25em .7em;
      color: var(--primary-gray);

      &::placeholder {
        opacity:.3;
      }

      &:focus {
        outline: none !important;
        border-color: var(--primary-green) !important;
      }
    }

    input:nth-child(3) {
      width: 100%;
    }
    // input:first-child { // email
    //   order: 3;
    //   width: 100%;
    // }

    // input:nth-child(2) { // first name
    //   order: 1;
    //   flex: 1;
    //   margin-right: 1em;
    // }

    // input:nth-child(3) { // last name
    //   order: 2;
    // }
  
    button {
      order: 4;
      display: block;
      background: var(--primary-blue);
      border-radius: 20px;
      border: none;
      padding: .5em 1.4em;
      color: #ffffff;
      margin-top: .5em;

      &:hover {
        filter: contrast(1.4);
      }

    }

    .msg-alert {


      p {
        font-size: 1.1em;
        margin-top: .5em;
        // margin-bottom: 0px;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        left: 0; top: 0;
        z-index:2;
        background: #ffffff;
      }
    }

  }
}




// Tablet Styles

@media (max-width: 1224px) {

  .container {
    max-width: 700px;
  }
  .consumer-hero-wrapper {

    .container {

      padding: 3em 0;
      > .row {
        margin: 0px;
      }

    }
    
      

    .animation-wrapper {
      height: unset;
    }

    .jumbotron {
      padding: 2em;
      text-align: center;
  
      footer {
        justify-content: center;
      }
    }
  }

  .why-carousel {

    .row {
      margin: 0px;
    }

    .col {
      padding: 0 12px;
    }
  }



  .how-wrapper {
    padding: 4em 1em;
    .row {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    .container div:nth-child(2) article, .container article {
      position: relative;
      &:before {
        font-size: 170px !important;
        text-align: center;
        left: 50%;
      }
    }
  }

  .video-section-wrapper {
    .title-icon-stacked {
      margin: 0em 2em 0 0 !important;
    }
  }

  .features-wrapper {
    margin-top: -3em;
    padding-top: 6.5em !important;
    padding-bottom: 4em !important;
  
    .row:last-child {
      margin-top: 1em;
    }
  
    .title-icon {
      margin-bottom: 1em;
      img {
        margin-right: .75em;
        margin-left: 0px;
      }
    }
  }

  .partners-wrapper {
    padding: 4em 1em;
    .row {
      justify-content: center;
      margin: 0;
    }
    .col-md-5 {
      width: 100%;
      max-width: 100%;
      flex: unset;
      .title-icon {
        flex-direction: column;

        h2 {
          margin-top: .5em;
          text-align: center;
        }
      }
    }
    .col-md-7 {
      width: 100%;
      max-width: 100%;
      flex: unset;
      margin-top: 1em;
    }
  }

  .mailchimp-form-wrapper {
    padding: 3em 1em;
  }




}




// Mobile Styles

@media (max-width: 767px) {

  .consumer-hero-wrapper {
    padding: .0em 0;

    .row {
      flex-direction: column-reverse;
    }

    .jumbotron {
      padding: 0px;
      padding-top: 1.5em;
    }
    footer {
      flex-direction: column;
      p {
        padding: .5em 0 !important;
      }
    }
  }

  .how-wrapper {
    padding: 4em 1em .6em 1em !important;
    div {
      justify-content: center !important;
      text-align: center;
    }
    .container > div:nth-child(3) {
      flex-direction: column-reverse;
      
    }
  }

  .consumer-video-wrapper {
    img:last-of-type {
      width: 90px;
    }  
  }

  .features-wrapper .container > .row div:nth-child(1) {
    order: 2;
    margin-top: .5em;
  }
  .features-wrapper .container > .row div:nth-child(2) {
    order: 3;
  }
  .features-wrapper {
    .title-icon {
      justify-content: center;
    }

    p {

      text-align: center;
    }
  }

  .for-professionals-wrapper {
    padding: 4em 0 0 0;
    img {
      margin-bottom: 2em;
    }
    .col {
        padding-left: 15px !important;
        text-align: center;
    }
  }

  .faq-wrapper {
    .card-header:after {
      display: none;
      line-height: 1em;
    }
    .accordion {
      margin-top: 1.5em;
    }

    .card-body {
      padding: 1em 0em;
    }
  }

  .infographic-wrapper {
    padding: 0;
    margin: 0;
    text-align: center;
    flex-direction: column-reverse;

    .form-inline {
      justify-content: center;
    }

    .col {
      text-align: center;
    }

    img {
      margin-bottom: 2.5em;
    }
  }


  .mailchimp-form-wrapper {
    padding: 2em 0;
  
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-top: .5em;
      position: relative;
  
      input {
        width: 100%;
        border-color: var(--primary-blue) !important;
        // border-image: unset !important;
        // background-color: unset !important;
        border-style: solid !important;
        padding: .25em .7em;
        color: var(--primary-gray);
  
        &::placeholder {
          opacity:.3;
        }
  
        &:focus {
          outline: none !important;
          border-color: var(--primary-green) !important;
        }
      }
      input:nth-child(2) {
        margin: .5em 0%;
      }
    
      button {
        order: 4;
        display: block;
        background: var(--primary-blue);
        border-radius: 20px;
        border: none;
        padding: .5em 1.4em;
        color: #ffffff;
        margin-top: .5em;
  
        &:hover {
          filter: contrast(1.4);
        }
      }
  
      .msg-alert {
  
  
        p {
          font-size: 1.1em;
          margin-top: .5em;
          display: block;
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          left: 0; top: 0;
          z-index:2;
          background: #ffffff;
        }
      }
  
    }
  }

  .consumer-landing-cta {
    padding: 4em 0; 
  }



}