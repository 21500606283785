.product-page-wrapper {
  color: var(--secondary-color);
}

#product {
  background-color: #bfe3d7;
  opacity: 1;
  width: 100%;
}

.product-single-title span {
  display: inline-block;

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.products-hero-wrapper {
  transition: all 200ms ease;
  position: relative;
  padding: 10em 0;

  .video-background,
  .video-foreground {
    position: absolute;
  }

  .video-foreground:after {
    content: "";
    display: block;
    background: url("../photos/for-professionals/professionals-hero-overlay.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .col .title-icon-stacked {
    align-items: center;
  }
}

.product-details-wrapper {
  p {
    margin-top: 1em;
  }
}

.product-carousel-section-wrapper {
  padding: 80px 0px;
  .row {
    margin: 0px;
  }
}

.product-details-section {
  margin-top: 4em;
  align-items: flex-start;
  overflow: hidden;

  .col:first-child {
    padding: 2em 3em 0 0;
    display: flex;
    justify-content: flex-end;

    div {
      max-width: 500px;
    }
  }

  .title-icon-stacked {
    margin: 0 0 0 4em;
    align-items: flex-start;
    text-align: right;
    img {
      align-self: flex-end;
      margin-right: -18px;
    }
  }

  .product-images-wrapper {
    // background: url('../photos/for-professionals/applications-bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: -6px;

    .carousel {
      padding: 0px !important;
      position: relative !important;
    }

    .carousel-control-next,
    .carousel-indicators {
      transform: translateY(-4em);
    }

    .carousel-item {
      position: relative;
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        mask-size: contain;
        // mask-position: center;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        mask-image: url("../photos/products/image-mask.svg");
      }

      .pro-image-1 {
        background-image: url("../photos/products/mobile-image-1.jpg");
      }
      .pro-image-2 {
        background-image: url("../photos/products/mobile-image-2.jpg");
      }
      .pro-image-3 {
        background-image: url("../photos/products/mobile-image-3.jpg");
      }
      .desktop-image-1 {
        background-image: url("../photos/products/desktop-image-1.jpg");
      }
      .desktop-image-2 {
        background-image: url("../photos/products/desktop-image-2.jpg");
      }
      .desktop-image-3 {
        background-image: url("../photos/products/desktop-image-3.jpg");
      }
      .dashboard-image-1 {
        background-image: url("../photos/products/dashboard-image-1.PNG");
      }
      .dashboard-image-2 {
        background-image: url("../photos/products/dashboard-image-2.PNG");
      }
      .dashboard-image-3 {
        background-image: url("../photos/products/dashboard-image-3.PNG");
      }
      .dashboard-image-4 {
        background-image: url("../photos/products/dashboard-image-4.PNG");
      }
      img {
        opacity: 0;
      }
    }

    .carousel {
      position: absolute;
      padding: 3em 3.5em;
    }

    hr {
      background: #ffffff;
    }
    .carousel-indicators li.active {
      background: #359ee3;
    }
    .carousel-indicators li {
      background: #f1f2f3;
    }
    .carousel .carousel-indicators li.active {
      border-color: #f1f2f3 !important;
    }

    .carousel-control-next img {
      // filter: brightness(5);
    }
    h3,
    p {
      color: #ffffff;
    }
  }
}

.product-features-wrapper {
  margin-top: -13em;
  padding-top: 12em !important;

  .row {
    align-items: flex-start;
  }

  .row:last-child {
    margin-top: 3em;
  }

  .title-icon {
    margin-bottom: 1em;
    img {
      margin-right: 0.75em;
      margin-left: 0px;
    }
  }
}

#description {
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    align-self: center;
    justify-self: center;
  }

  .container {
    min-width: 200px;
    margin: 5% auto;

    .col-lg-8 {
      margin-bottom: 5%;

      h1 span {
        text-transform: capitalize;
      }

      p a {
        color: black;
      }
    }

    .col-lg-4 {
      margin-bottom: 5%;
    }

    .row-align-text {
      text-align: center;
    }
  }
}

#product .card {
  -webkit-animation-name: product_appear; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  animation-name: product_appear;
  animation-duration: 4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes product_appear {
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0px);
  }
}

/* Standard syntax */
@keyframes product_appear {
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0px);
  }
}

.product_sec_a {
  margin: 2rem 0;
}

.product_sec_a .container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;
}

.product_sec_a h1 {
  font-size: 4rem;
  /* color: var(--primary-color); */
}

.product_sec_a p {
  margin: 2rem 0;
}

.product_sec_a img {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

/* Pricing Table CSS  */
section#pricing_table div.row {
  /* background-color: aqua; */
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

#pricing_table {
  table {
    overflow: hidden;
  }

  td,
  th {
    cursor: pointer;
    padding: 10px;
    position: relative;
  }

  th {
    border-top-width: 0px;
    padding-top: 10px;
  }

  tr {
    text-align: center;
  }

  hr {
    margin: 0 auto 20px auto;
    max-width: 50px;
  }

  .row {
    min-height: 10vh;
    justify-content: center;
  }
}

// #pricing_table td:not(:first-child):hover::after, th:not(:first-child):hover::after {
//   background: radial-gradient(#bee3f7, #ffffff);
//   content: '\00a0';
//   height: 10000px;
//   left: 0;
//   position: absolute;
//   top: -5000px;
//   width: 100%;
//   z-index: -1;
// }

// #pricing_table tbody tr td:nth-child(1) {
//   text-align: left;
// }

#up-1 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 100%);
  margin-left: 1%;
  margin-right: 1%;
}

#up-2 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
  margin-left: 1%;
  margin-right: 1%;
}

#up-3 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(to right, rgb(236, 3, 3) 0%, rgb(252, 128, 142) 100%);
  margin-left: 1%;
  margin-right: 1%;
}

#down-1 {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 100%);
}

#down-2 {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
}

#down-3 {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: linear-gradient(to right, rgb(236, 3, 3) 0%, rgb(252, 128, 142) 100%);
}

#feature-1 {
  background: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 100%);
  margin-left: 1%;
  margin-right: 1%;
}

#feature-2 {
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
  margin-left: 1%;
  margin-right: 1%;
}

#feature-3 {
  background: linear-gradient(to right, rgb(236, 3, 3) 0%, rgb(252, 128, 142) 100%);
  margin-left: 1%;
  margin-right: 1%;
}

.cart-modal {
  line-height: 1em;

  h3 {
    margin-top: 1em !important;
  }

  .col,
  .col-lg-4,
  .col-lg-3 {
    padding: 0px;
  }

  color: var(--primary-gray);
  .modal-body,
  .modal-footer {
    padding: 2em !important;
  }

  .card,
  .modal-footer {
    border: none;
  }

  .modal-footer {
    padding-top: 0px !important;
  }

  .row {
    margin: 0px !important;
    align-items: flex-start;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    text-align: center;
    border: none;
    background: none;
  }

  .cart-col-left {
    > .row {
      align-items: center;
    }

    .numeric-input-wrapper {
      align-items: center;
      text-align: left;
      margin: 10px 0;

      input {
        border-radius: 5px !important;
        border: none;
        background: #f2f2f2;
      }

      > .col-lg-4 {
        padding-left: 0px;
      }
    }

    .subtotal-wrapper {
      align-items: center;
      margin: 10px 0;

      > .col {
        padding-left: 0px;
      }

      .green-text {
        color: var(--primary-green);
        margin-top: 8px;
      }

      .old-price-text {
        text-decoration: line-through;
        color: red;
      }
    }
  }

  .cart-col-right {
    > .row:first-child {
      margin: 10px 0;
    }

    .query-min-600 {
      background-color: #f7f9fc;
      border-radius: 5px;
      font-size: 75%;
      margin: 10px 0;
      padding: 1em 0.5em;

      > .col-4 {
        text-align: left;
        margin: auto;
      }

      > .col-8 {
        text-align: right;

        span {
          font-weight: bold;
        }

        button {
          transform: scale(0.7);
          margin-right: -20px;
        }
      }
    }

    .query-max-599.top-row {
      background-color: #f7f9fc;
      border-radius: 5px;
      font-size: 75%;
      margin: 10px 0;

      > .col {
        text-align: left;
        margin: auto;
      }
    }

    .query-max-599.bottom-row {
      background-color: #f7f9fc;
      border-radius: 5px;
      font-size: 75%;
      margin-bottom: 10px;

      > .col {
        text-align: left;

        span {
          font-weight: bold;
        }

        button {
          background-color: #ffd6d6;
        }
      }
    }

    .cart-total-wrapper {
      align-items: center;
      margin: 10px 0;
    }
  }
}

.two-buttons {
  a:first-child {
    margin-right: 1em;
  }
  a:last-child {
    line-height: 1.45em;
  }
}

// Tablet

@media (max-width: 1224px) {
  .products-hero-wrapper {
    .container {
      padding: 0em 0;
      > .row {
        margin: 0px;
      }
    }
  }

  .product-details-section {
    .col:first-child {
      padding: 0 3em 3em 3em;

      div {
        max-width: 100%;
        text-align: center;
      }
    }

    .product-images-wrapper {
      background-size: cover;
      background-position: center;
      > img {
        display: none;
      }
      .carousel {
        position: relative;
      }
    }
  }

  .product-features-wrapper {
    margin-top: -13em;
    padding-top: 11.5em !important;
    padding-bottom: 4em !important;

    .row:last-child {
      margin-top: 1em;
    }

    .title-icon {
      margin-bottom: 1em;
      img {
        margin-right: 0.75em;
        margin-left: 0px;
      }
    }
  }
}

// Mobile

@media (max-width: 767px) {
  .product-features-wrapper .container > .row div:nth-child(1) {
    order: 2;
    margin-top: 0.5em;
  }
  .product-features-wrapper .container > .row div:nth-child(2) {
    order: 3;
  }
  .product-features-wrapper {
    .title-icon {
      justify-content: center;
    }

    p {
      text-align: center;
    }
  }
}
