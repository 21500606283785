.professionals-hero-wrapper {
  transition: all 200ms ease;
  position: relative;
  padding: 4em 0;

  .video-background, .video-foreground {
    position: absolute;
  }

  .video-foreground:after {
    content: "";
    display: block;
    background: url('../photos/for-professionals/professionals-hero-overlay.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .jumbotron {
    background-color: rgba(0,0,0,0);
    position: relative;
    z-index: 1;

    h1, p { 
      color: #ffffff;
      text-align: right;
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn-secondary {
        background: #ffffff;
        color: var(--primary-blue);
        margin-left: 1em;

        &:hover {
          filter: contrast(1.4);
          background: var(--primary-blue);
          color: #ffffff;
        }
      }

    }
  }

}

section.clinical-challenges-wrapper {
  background: url('../photos/bulge-bottom.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: -40px !important;
  position: relative;
  z-index:3;
  padding-top: 7em;
  padding-bottom: 0em;

  h3, img, p {
    text-align: center;
  }

  .row {
    padding: 2em;
  }
  .col {
    padding: 1.5em;
    transition: all 400ms ease;
    border-radius: 30px;
    text-align: center;

    &:hover {
      background: rgba(108,125,133,.05);
      transform: scale(1.02);
    }
  }
}

.desktop-solution-wrapper {
  background-position: 0% 0%, 0% 94%;
  .col-lg-7 img {
    mix-blend-mode: multiply;
  }  
}

.mobile-solution-wrapper {
  .col-lg-7 img {
    margin-left: -3em;
  }  
}

.application-section-wrapper {
  margin-top: 3em;
  align-items: center;

  .title-icon-stacked {
    margin: 0 0 0 4em;
    align-items: flex-start;
    text-align: right;
    img {
      align-self: flex-end;
      margin-right: -18px;
    }
  }

  .professionals-applications-wrapper {
    background: url('../photos/for-professionals/applications-bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .carousel {
      position: absolute;
      padding: 3em 3.5em; 
    }

    hr {
      background: #ffffff;
    }
    .carousel-indicators li.active {
      background: #359EE3;
    }
    .carousel-indicators li {
      background: #ffffff;
    }
    .carousel .carousel-indicators li.active {
      border-color: #ffffff !important;
    }

    .carousel-control-next img {
      filter: brightness(5);
    }
    h3, p {
      color: #ffffff;
    }
  }
}

.feature-comparison-wrapper {
  margin-top: -5em;
  padding-top: 10em;

  table {
    overflow: hidden;
  }
  thead {
    text-align: center;

    h2 {
      text-align: left;
      line-height: 1em;
    }

    .title-icon {
      margin-left: -35px;
    }
  }
  tbody {
    margin-top: 2em;
  }
  thead, tr, td, th, tbody {
    border: none !important;
  }
  td {
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-align: center;
    font-size: 1.1em;
  }
  td:first-child {
    text-align: left;
    color: var(--primary-gray);
  }
  tr:nth-child(2n) {
    background: var(--primary-gradient-footer);
    background: linear-gradient(90deg, rgba(42,147,208,0.1) 0%, rgba(69,179,73,0.1) 100%);
    border-radius: 50px;
  }
  tr:first-child td:nth-child(2) { border-radius: 50px 50px 0 0; }
  tr:last-child td:nth-child(2) { border-radius: 0 0 50px 50px; }

  tr:last-child {
    background: none;
  }
  tr td:first-child { border-top-left-radius: 50px; }
  tr td:last-child { border-top-right-radius: 50px; }
  tr td:first-child { border-bottom-left-radius: 50px; }
  tr td:last-child { border-bottom-right-radius: 50px; }

  td.no {
    color: var(--primary-gray);
  }
  td {
    color: var(--primary-green);
    position: relative;
    z-index: 3 !important;
    transition: all 200ms ease;
    &:before {
      background: rgba(42,147,208,.1);
      content: '';  
      height: 2000px;   
      left: 0;
      position: absolute;  
      top: -1000px;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      z-index: 1; 
      transition: all 700ms ease;  
    }
  }
  td:not(:first-child):hover::before, th:not(:first-child):hover::before { 
    opacity: .5;
  }

  &::before {
    display: block; 
    content: " "; 
    margin-top: -285px; 
    height: 285px; 
    visibility: hidden; 
  }
  .btn-primary {
    font-weight: 400;
  }
}

.client-home-cta-wrapper {
  .row {
    padding-top: 3em;
  }
}

.professionals-partners-wrapper {
  .title-icon {
    justify-content: flex-end;
  }
  .row {
    margin: 0px;
  }
}

.testimonial-carousel {
  padding-top: 5em;

  .carousel-inner {
    padding-bottom: 2.5em;
  }

  article {
    text-align: left;

    > div {
      display: flex;
      align-items: center;

      img {
        margin-right: 1em;
        border-radius: 100px;
      }

      p {
        margin: 0px;
      }
    }
  }
  hr {
    margin: 0 0 2em 0 !important;
  }

  blockquote {
    text-align: left;
    em{
      color: var(--primary-blue);
    }
  }
  .testimonials-video-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  
    .video-play-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 200ms ease;
      cursor: pointer;
  
      &:hover {
        // transform: translate(-50%, -50%) scale(1.03);
        width: 160px;
        height: 160px;
      }
    }
  
  }
}

.professionals-infographic-wrapper {
  padding: 3em 0;

  .row {
    margin: 0px;
  }

  .col-md-8 {
    padding-left: 3em;
  }
  input {
    border-radius: 100px;
    border: solid 4px var(--primary-blue);
    margin-right: 1.5em;
    margin-left: -5px;
    transform: scale(.96);
    &:focus {
      border-color: var(--primary-green);
      transform: scale(.96);
      box-shadow: none;
    }
  }
}




// Tablet Styles

@media (max-width: 1224px) {

  .container {
    max-width: 700px;
  }
  .professionals-hero-wrapper {
    .container {

      padding: 0em 0;
      > .row {
        margin: 0px;
      }

    }  
      
    .animation-wrapper {
      height: unset;
    }

    .jumbotron {
      padding: 2em 0;
      text-align: center !important;

      h1, p {
        text-align: center !important;
      }
  
      footer {
        justify-content: center;
      }
    }
  }

  .mobile-solution-wrapper {
    .col-lg-7 img {
      margin-left: -2em;
    }
  }

  .desktop-solution-wrapper .row {
    flex-direction: column-reverse;
  }
  .desktop-solution-wrapper article, .mobile-solution-wrapper article {
    text-align: center;
  }

  section.clinical-challenges-wrapper {
    margin-top: -20px !important;
  }

  .why-carousel {

    .row {
      margin: 0px;
    }

    .col {
      padding: 0 12px;
    }
  }

  .application-section-wrapper {
  
    .title-icon-stacked {
      margin: 2em 0 2em 0 !important;
      align-items: center !important;
      text-align: center;

      img {
        margin-right: 0px;
      }

      h2 {
        text-align: center;
      }

    }

    .professionals-applications-wrapper {
      background-size: cover;
      background-position: center;
      > img {
        display: none;
      }
      .carousel {
        position: relative;
      }
    }
  }

  .feature-comparison-wrapper {
    td {
      font-size: 1em;
    }

    a {
      line-height: 1em !important;
    }

  }


  .professionals-partners-wrapper {
    .container > .row:first-child {
      flex-direction: column-reverse;
    }
    padding: 4em 1em;
    .row {
      justify-content: center;
      margin: 0;
    }
    .col-md-5 {
      width: 100%;
      max-width: 100%;
      flex: unset;
      .title-icon {
        flex-direction: column;

        h2 {
          margin-top: .5em;
          text-align: center;
        }
      }
    }
    .col-md-7 {
      width: 100%;
      max-width: 100%;
      flex: unset;
      margin-top: 1em;
    }
  }

  .testimonial-carousel {
    .row {
      flex-direction: column-reverse;
    }
    .row div:last-child {
      margin-bottom: 1.5em;
    }
  }



}




// Mobile Styles

@media (max-width: 767px) {


  section.clinical-challenges-wrapper {
    margin-top: -10px !important;
    padding-top: 5em;
  }

  section.mobile-solution-wrapper {
    padding-top: 1em;

    .col-lg-7 img {
      margin-left: -1em;
    }

  }

  section.desktop-solution-wrapper {
    padding-left: 0px;
    padding-right: 0px;

    .carousel {
      padding-left: 1.5em !important;
      padding-right: 1.5em !important;

      p {
        font-size: .8em;
      }
    }
  }

  .feature-comparison-wrapper {
    tr td:first-child {
      font-size: .8em;
      padding: 1em 0em 1em 2em;
      line-height: 1.1em;
    }
    td {
      padding-left: .8em;
      padding-right: .8em;
      min-width: 150px;
      vertical-align: middle;
      .btn {
        padding-left: .95em;
        padding-right: .95em;
      }
    }
  }

  .testimonials-video-wrapper {
    img:last-of-type {
      width: 90px;
    }
  }


  .faq-wrapper {
    .card-header:after {
      display: none;
      line-height: 1em;
    }
    .accordion {
      margin-top: 1.5em;
    }

    .card-body {
      padding: 1em 0em;
    }
  }

  .professionals-infographic-wrapper {
    padding: 0;
    margin: 0;

    .form-inline {
      justify-content: center;
    }

    .col-lg-6:last-child {
      margin-top: 2em;
      text-align: center;
      padding: 0px;
    }

  }



}
