.preloader-wrapper {
  z-index: 48;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6em;
}


.module-date-selection {
  padding: 20px;

  .row>.col {
    display: flex;
    align-items: center;
    min-height: 40px;

    // padding-left: 0px;
    div {
      margin-right: 5px;
    }
  }
}


.posture-date-overlay-wrapper {
  div.popover-body {
    padding: 20px;
    min-width: 300px;
  }
  div {
    ul {
      padding-left: 0px;
      margin: 0px;
    }
    .active-date {
      background: #ffffff !important;
      color: var(--primary-green);
      width: 100%;
      text-align: left;
      position: relative;

      img {
        position: absolute;
        right: 0.75em;
        top: 14px;
      }
    }
    .white-link {
      background: transparent;
      color: white;
    }
  }
}

.forceplate-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.forceplate-card {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(0 0 0 / 3%), 0 0.9375rem 1.40625rem rgb(0 0 0 / 3%), 0 0.25rem 0.53125rem rgb(0 0 0 / 5%), 0 0.125rem 0.1875rem rgb(0 0 0 / 3%);
  transition: all .2s;
  border-radius: 5px;
}

h6 {
  font-size: 0.75rem;
  font-weight: 600;
}