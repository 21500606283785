.pricing-section-wrapper {

  .row {
    align-items: flex-start;
  }

  .container {
    padding-top: 3em;
    padding-bottom: 2em;
  }

  .title-icon {
    margin-bottom: 3em;
  }

  .card {
    text-align: center;
    align-items: center;
    background: var(--primary-gradient-light);
    border-radius: 25px;
    border: none;
    padding: 3em 2em;
  }

  h3 {
    color: var(--primary-gray);
    font-weight: 300;
    font-size: 2.5em;
  }

  h3 + p {
    margin-top: -13px;
    font-size: .8em;
  }

  ul {
    text-align: center;
    padding-left: 0px;
    color: var(--primary-green);
    .excludes {
      color: var(--primary-gray);
      text-decoration: line-through;
    }

    li {
      margin: .75em 0;
    }
  }
  .product-coming-soon {

    height: 32.3em;
    
    img {
      margin-left: -9px;
    }
    
    div {
      background: var(--primary-gradient-light);
      border-radius: 25px;
      padding: 3em;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .btn-outline-secondary {
    padding: .3em 1.1em;
    line-height: 1.3em;
  }

  .learn-more-btn {
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 1em;
    &:hover {
      filter: contrast(1.4);
    }
  }

  br {
    margin-top:20px;
  }
  
  
}

// Mobile Styles

@media (max-width: 767px) {

  .pricing-section-wrapper {

    .col {
      margin: 1em 0;
    }
  }
  .product-coming-soon {
    max-height: 15em;    
  }




}
