.contact-background {
    background: url('../photos/contact-us/contact.jpg') 100% 100% no-repeat;
    min-height: 50vh;
}
.container-fluid.contact-page {
    padding: 0px;
}

.contact-form-wrapper {

    .card {
        background: url('../photos/contact-us/form-bg.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        outline: none;
        padding: 3em 3em;
    }

    form > .form-row:nth-child(-n+3) label{
        display: none;
    }

    input, textarea {
        border-radius: 20px;
        border: none;
    }

    .form-footer-wrapper {
        display: flex;
        padding: 0 2px 0 6px;

        label {
            color: var(--primary-blue) !important;
        }
    }

}

.contact-section-wrapper {
    .row {
        margin: 0px !important;
    }
}


// Tablet 

@media (max-width: 1224px) {
    .contact-section-wrapper .container {
        max-width: 1000px;

        > .row > div:first-child {
            align-items: center;
            text-align: center;
            justify-content: center;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
        }

    }
}

@media (max-width: 767px) {
    .contact-section-wrapper .container {
        max-width: 1000px;

        .card {
            padding: .5em;
            background-size: cover;
            margin-top: 2em;
            border-radius: 30px;

            .form-footer-wrapper {
                flex-wrap: wrap;
                justify-content: center;
                button {
                    margin-top: 1em;
                }
            }
        }

        > .row > div:first-child {
            align-items: center;
            text-align: center;
            justify-content: center;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
        }

    }
}