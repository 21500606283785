#component-challenge {
    text-align: center;
    background-color: #fff; 
    color: var(--secondary-color);
    padding: 30px 0;

    .row {
        align-items: stretch;
        align-items: center;
        justify-content: center;
    }

    .header.row {
        min-height: 15vh;
    }

    hr {
        max-width: 50px;
        margin: 0 auto 20px auto;
    }

    .body.row {

        div[class*="col-"] {
            align-self: center;
            padding: 2%;
        }
    }

    img {
        max-width: 80%;
        max-height: 80%;
        width: 65%;
        height: auto;
        align-self: center;
        justify-self: center;
    }
}