// general
.blue-hue {
  color: blue;
}

.loading-bg {
  position: relative;
}

.hide {
  display: none !important;
}

.login-hidden {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.8;

  input {
    background: #ffffff !important;
  }
}

.knowledge-base-div h3,
.knowledge-base-div img {
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    opacity: 0.8;
  }
}

.knowledge-base-div {
  .kb-row {
    display: flex;
    align-items: flex-start;
  }
}

.knowledge-base-div img {
  margin-bottom: 1em;
}

.error-login {
  color: #dc3545;
  text-align: center;
  position: absolute;
  bottom: 26px;
  left: 0px;
  right: 0px;
  font-size: 14px;
}

.popover {
  border-radius: 20px;
}

.max-wrapper {
  margin-top: 2em;
}

.help-button {
  border-radius: 50px;
  background: #c5cbce;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-right: 1.5em;
  height: 35px;
  width: 35px;

  span {
    color: #ffffff;
  }

  &:hover {
    background: var(--primary-gray);
  }
}

.organization-logo-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0px;
  margin-top: auto;
}

.logo-preview {
  >div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em !important;

    img {
      margin-bottom: 10px !important;
    }

    .remove-image {
      // position: absolute;
      // top: 5px;
      // right: 5px;
      background: #dc3545;
      color: #ffffff;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease;
      border-radius: 40px;
      padding: 2px 10px;
      cursor: pointer;

      &:hover {
        filter: contrast(1.4);
      }
    }
  }
}

.upload-wrapper {
  margin-top: 8px;

  label {
    border-radius: 20px !important;
    text-align: left;
    font-size: 0.82em;
    color: var(--primary-gray);
    line-height: 1em;
    background: var(--primary-gradient-light);
    border: none;
    display: flex;
    align-items: center;
    padding-left: 0.9em;
  }

  label::after {
    border-radius: 20px !important;
    font-size: 1em;
    line-height: 1em;
    padding: 12px 25px;
    height: 100%;
    border: none;
    background: var(--primary-blue);
    color: #ffffff;
    transition: all 200ms ease;
    cursor: pointer;
    z-index: 4;

    &::hover {
      filter: contrast(1.4);
    }
  }
}

.terms-wrapper {
  label {
    margin-top: 4px;
  }

  margin-bottom: 1em !important;
}

.coupon-wrapper {
  button {
    margin-left: 1em;
  }

  .invalid-feedback {
    max-width: 100px;
    position: absolute;
    left: 3em;
    bottom: 10px;
  }
}

.preloader-wrapper {
  z-index: 48;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6em;
}

.align-items-center {
  align-items: center;
}

.a-button {
  border-radius: 30px;
  padding: 8px 25px;
  background: var(--primary-blue);
  color: #ffffff;
  margin-top: 0.5em !important;
  display: inline-block;

  &:hover {
    filter: contrast(1.4);
    color: #ffffff;
  }
}

.modal-overlay {
  z-index: 50;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6em;

  .modal-container {
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    padding: 3em;
    // pointer-events: none !important;

    .modal-close-button {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

.create-org-wrapper {
  .inline-button-input {
    .row {
      margin-top: 0px !important;
    }
  }
}

.main-alert {
  margin: 0px;
  padding: 0.3em 2em;
  border-radius: 0px;

  div {
    justify-content: center;
  }

  p {
    text-align: center;
    margin: 0px;
    color: inherit;
    font-size: 0.8em;
  }
}

.delete-user-wrapper {
  margin-left: auto;
  margin-right: 0.75em;
}

.loading-bg:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../photos/loading-bg.gif");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.MuiDataGrid-root {
  font-family: "Rubik" !important;
}

.exit-link {
  position: absolute;
  top: 3em;
  left: 5em;
}

// .ui-switch.monthlyChecked label p {
//   color: var(--primary-green) !important;
//   opacity: 1;
// }

.license-nav-wrapper {
  margin-top: 2em;
}

.invalid-feedback {
  position: absolute;
  z-index: 4;
  bottom: -14px;
  font-size: 13px;
  text-align: left;
}

.ui-switch {
  display: flex;
  position: relative;
  padding: 0px !important;

  >p {
    text-align: right;
    position: relative;
    left: -50px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  label {
    p {
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  p,
  label p {
    opacity: 0.4;
    color: var(--primary-gray);
    cursor: pointer !important;
  }

  &.monthlyChecked label p,
  &.yearlyChecked>p {
    color: var(--primary-green);
    opacity: 1;
  }

  .custom-control-label::before {
    border-color: var(--primary-green);
    background-color: var(--primary-green);
  }

  .custom-control-label::after {
    background-color: #ffffff;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary-green);
    background-color: var(--primary-green);
  }
}

// forms
.landing-form-section-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 1em;

  .row {
    justify-content: center;

    >div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .row:last-child {
    margin-top: 1em;
  }

  .landing-form-wrapper {

    .form-control.is-invalid,
    .was-validated .form-control:invalid,
    .form-control.is-valid,
    .was-validated .form-control:valid {
      background-image: none;
    }

    justify-content: center;
    text-align: center;

    img {
      margin-bottom: 2em;
    }
  }

  input {
    background: var(--primary-gradient-light);
    border: none;
    padding: 0.25rem 1em;
  }

  div {
    margin-bottom: 2px;
  }
}

.form-section-wrapper {
  .row:last-child {
    margin-top: 1em;
  }

  form {
    margin-left: -0.6em;
  }

  label {
    margin-bottom: 0px;
    font-size: 0.8em;
    font-weight: 600;
    padding: 0 1em;
  }

  button {
    margin-left: 1em;
  }

  .ui-select {
    margin: 0.5em 0;
  }

  input {
    background: var(--primary-gradient-light);
    border: none;
    padding: 0.25rem 1em;
  }

  div {
    margin-bottom: 5px;
  }
}

// side nav
.side-nav {
  background: var(--primary-gradient-light);
  min-height: 100vh;

  width: 250px;
  padding: 1.5em 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;

  &.side-collapsed {
    width: auto;
    align-items: center;
    min-height: 100%;

    .organization-toggle {
      // padding: 0em;
      width: 50px;
      height: 50px;
    }

    hr {
      width: 40px;
    }

    button img {
      width: 20px;
      margin-right: 0px !important;
    }

    article {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .side-nav-collapse-icon {
      position: fixed !important;
      bottom: 2em;
      align-self: center;

      &.reverse {
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateX(-2px);
        }
      }
    }
  }

  article {
    width: 100%;
  }

  .side-nav-button {
    border-radius: 40px;
    border: none;
    padding: 0.75em 1em;
    width: 100%;
    text-align: left;
    font-weight: 500;
    display: flex;
    align-items: center;
    background: none;

    &.active-sn {
      background: rgba(69, 179, 73, 0.2);
    }

    img:first-child {
      margin-right: 0.75em;
    }

    img.caret.active-caret {
      transform: rotate(90deg);
    }
  }

  .side-nav-button-dd {
    padding: 0.5em 1em 0em 3.25em;
    margin-bottom: 0px;

    li {
      padding: 0.35em 0;
    }

    a {
      font-weight: 500;
      color: var(--primary-gray);

      &:hover {
        color: var(--primary-green);
        filter: none;
      }
    }

    .active-dd-link {
      color: var(--primary-green);
    }

    .side-nav-nested-ul2 {
      padding: 0.25em 0 0 1em;

      a {
        font-weight: 300;
      }
    }
  }

  .toggle-collapse-icon {
    cursor: pointer;
  }

  .side-nav-collapse-icon {
    position: fixed !important;
    bottom: 1.6em;
  }
}

.info-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-top: 1px;
  width: 15px;
  height: 15px;
  font-size: 10px;
  margin: 0 1em;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.organizations-wrapper {
  ul {
    padding-left: 0px;
  }

  .active-organization {
    background: #ffffff !important;
    color: var(--primary-green);
    width: 100%;
    min-width: 180px;
    text-align: left;
    position: relative;
    padding-right: 2em;

    img {
      position: absolute;
      right: 0.75em;
      top: 14px;
    }
  }
}

.organization-toggle {
  width: 100%;
  background: #ffffff;
  color: var(--primary-blue);
  margin-bottom: 1.25em;
  margin-top: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-size: 1em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--primary-gray-light);
    color: var(--primary-blue);
  }

  &:focus {
    box-shadow: none;
    outline: none;
    background: #ffffff !important;
    color: var(--primary-blue);
  }

  img {
    // position: absolute;
    // right: 0.5em;
    // top: 5px;
  }
}

.side-nav-collapse-icon {
  align-self: flex-end;
  margin-top: auto;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    transform: translateX(-2px);
  }
}

// application
.application-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  // font-size: 0.875rem;

  button:focus {
    outline: none;
  }

  button,
  a {
    cursor: pointer;
  }

  main {
    padding: 5em 5em;
    color: var(--primary-gray);
    flex: 1;
    position: relative;
    min-width: 600px;
  }

  h1 {
    margin-bottom: 0.2em;
    font-weight: 500;
  }

  h5,
  h2 {
    margin-bottom: 1.25em;
  }

  h4 {
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 0.65em;
  }

  .duel-buttons-wrapper {
    >span {
      margin: 0 1em;
    }
  }

  .p-heading {
    margin-bottom: 5px;
  }

  .super-button-wrapper {
    align-items: flex-start;

    img {
      margin-top: -3px;
      margin-left: 0.5em;
    }
  }

  .max-participants-form-group {
    .form-group {
      margin-bottom: 0px !important;
      margin-top: -2px;
      padding: 0 0.35em;
      min-width: 100px;
    }

    p {
      margin-bottom: 0px;
      margin-right: 0.75em;
    }

    margin-bottom: 1em;
  }

  .license-paypal-link {
    margin-top: 1.5em;
    align-items: center;

    p {
      margin-bottom: 0px !important;
      margin-right: 0.5em;
    }

    img {
      transition: all 200ms ease;
    }

    a:hover img {
      filter: contrast(1.2);
    }
  }

  .info-details {
    align-items: flex-start;

    p {
      margin-bottom: 9px;
      font-size: 14px;
      line-height: 1.3em;

      span {
        color: var(--primary-blue);
        font-weight: 500;
        display: block;
        font-size: 17px;
      }
    }

    &.license-details-wrapper {
      .flex-row {
        button {
          margin: 0px;
        }
      }

      >div {
        flex: 1;
      }

      align-items: unset;

      .col-md-4 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-control-plaintext {
          border: none;
          display: inline;
          width: auto;
          color: var(--primary-gray);
          font-size: inherit;
          font-weight: inherit;
          opacity: 0;
        }
      }
    }
  }

  .license-card {
    display: flex;
    flex-direction: row;
    padding: 2.5em 3em;
    border: none;
    border-radius: 20px;
    background: var(--primary-gradient-light);
    position: relative;
    margin-bottom: 1.5em;
    margin-top: 10px;

    .active-tag {
      position: absolute;
      top: 0px;
      right: 4em;
      font-size: 0.8em;
      border-radius: 0 0 10px 10px;
      padding: 0.15em 1.5em;
      background: var(--primary-green);
      color: #ffffff;
    }

    &.active-plan {
      // border: solid 4px var(--primary-green);
      box-shadow: 10px 0px 10px;
      box-shadow: 0px 0px 0px 4px var(--primary-green) inset;
    }

    .license-card-cta-wrapper {
      min-width: 20%;
    }

    .license-card-name-wrapper {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      hr {
        margin-right: 0px;
      }

      h3 {
        text-align: right;
      }
    }

    >ul {
      margin: auto;
      margin-left: 0px;
      display: block;
      justify-content: center;
      flex-direction: column;
      column-count: 2;

      li {
        p {
          font-size: 0.7em;
          line-height: 1em;
          margin-bottom: 1em;
        }

        .includes {
          color: var(--primary-green);
        }

        .excludes {
          text-decoration: line-through;
        }
      }
    }

    >div:last-child {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .btn-outline-danger {
      padding: 0.17em 1.25em;
    }
  }

  form.license-form-wrapper {
    >.license-info-section-wrapper {
      margin-bottom: -5em;
      position: relative;
      z-index: 5;
    }

    h4 {
      margin-bottom: 0px;
    }
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.alert:not(.main-alert) {
  border-radius: 12px;
  border: none;
  padding: 0.25em 2.5em;
  margin: 0 0 0 2em;
  // width: 100%;
  // width: 100vw;
  // top: calc(-5em - 56px);
  // left: calc(-300px - 5em);
  // position: absolute;
  display: flex;
  justify-content: flex-start;
  transition: all 600ms ease;

  >div {
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 0px;
    }

    button {
      margin-left: 1em;
    }
  }
}

.page-info-bubble {
  background: var(--primary-gradient-light);
  border-radius: 20px;
  padding: 3em;

  ul {
    padding-left: 0px;
  }

  li {
    padding: 0.5em 0;
  }

  p {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 1.4em;
  }
}

.role-section-wrapper {
  display: flex;
  margin-top: 1em;
}

.ui-section-wrapper {
  section {
    padding: 0 !important;
  }

  section:not(:last-child),
  form section {
    margin-bottom: 2.7em;
  }

  .new-license-info-wrapper {
    .form-nav-price-wrapper {
      position: unset;
    }
  }

  .form-nav-price-wrapper2 {
    h3 {
      margin-top: 1em;
      margin-bottom: 0px;

      &:before {
        content: "$";
      }

      &.mo {
        &:after {
          content: "/month";
        }
      }

      &.yr {
        &:after {
          content: "/year";
        }
      }
    }

    .tax-text {
      margin-top: -2px;
      font-size: 0.6em;
      text-align: right;
    }
  }

  .form-nav-price-wrapper {
    position: sticky;
    top: 0em;
    right: 2em;
    text-align: right;
    background: #ffffff;
    border-radius: 20px;
    z-index: 4;
    padding: 1em 0.7em;
    margin-bottom: 3em;

    h3 {
      margin-top: 1em;
      margin-bottom: 0px;

      &:before {
        content: "$";
      }

      &.mo {
        &:after {
          content: "/mo";
        }
      }

      &.yr {
        &:after {
          content: "/year";
        }
      }
    }

    .tax-text {
      margin-top: -2px;
      font-size: 0.6em;
      text-align: right;
    }
  }
}

.invisible-reset {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.table-cta-top {
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: -1.3em;
  }
}

// tables
.ui-table-wrapper {
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    border-bottom: none;
    position: relative;
    min-height: 76px !important;
    max-height: 76px !important;
    line-height: 76px !important;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      bottom: 0px;
      background: var(--primary-gradient);
      height: 4px;
      left: 0px;
    }

    border-bottom-color: var(--primary-gradient);
  }

  .MuiDataGrid-root {
    border-radius: 20px;
    margin-top: 0.75em;
    background: var(--primary-gradient-light);
    border: none;

    .MuiDataGrid-cell {
      border-bottom: solid #ffffff 3px;
      color: var(--primary-gray);
    }
  }

  .MuiDataGrid-window {
    top: 76px !important;

    .trash-icon {
      margin-top: -2px;
    }
  }

  .MuiDataGrid-colCellWrapper,
  .MuiDataGrid-row,
  .MuiDataGrid-footer {
    >div:first-child {
      padding-left: 4em;
    }

    >div:last-child {
      padding-right: 4em;
    }
  }

  .MuiDataGrid-colCellTitle {
    color: var(--primary-gray);
  }
}

.action-toggle-wrapper {
  background-color: unset;
  max-width: 400px;
  width: auto;

  select {
    min-width: 120px;
    margin-bottom: 10px;
  }

  button,
  a {
    margin-bottom: 10px;
  }

  button {
    margin-left: 1em;
  }

  .margin-left {
    margin-left: 0.6em;
  }

  input {
    margin-top: 0px;
    background: var(--primary-gradient-light);
    border: none;
    // width: 100%;
  }

  .popover-body {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    // display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    background: #ffffff !important;
    overflow: auto;

    .flex-row {
      // a:last-child, button:last-child {
      //   margin-left: 1em;
      // }
    }
  }

  &.roles-toggle-wrapper {
    .flex-row {
      align-items: center;
    }

    .ui-select {
      margin-left: 0px;
    }

    h3 {
      margin-bottom: 0.5em;
    }

    form .row:last-child {
      .col {
        margin-bottom: 0px;
      }
    }

    input,
    select {
      padding-left: 14px;
      padding-right: 14px;
      line-height: 22px;
    }
  }
}

.roles-selection-wrapper {
  margin-bottom: 2em !important;

  .btn-link:hover {
    filter: contrast(1.4);
  }
}

.role-info.info-details {
  .col div p {
    margin-bottom: 0px;
  }
}

// top nav
.top-nav-wrapper {
  position: absolute;
  top: 2em;
  right: 3em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  p {
    margin-bottom: 0px;
    margin-right: 1.5em;
  }

  .notification-icon-wrapper {
    position: relative;

    .notification-toggle-icon {
      margin-top: 3px;
      margin-right: 1.5em;
      cursor: pointer;

      &.muted {
        cursor: default;
        pointer-events: none;
      }
    }

    span {
      position: absolute;
      top: 14px;
      right: 28px;
      bottom: 0px;
      left: 0px;
      color: #ffffff;
      text-align: center;
      pointer-events: none;
    }
  }
}

.notification-toggle-wrapper {
  &.some-news {
    max-width: 600px;
  }
}

.notifications-list {
  margin-bottom: 0px;
  padding: 0px;

  p {
    margin-bottom: 0px !important;
  }

  li {
    position: relative;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 0.5em 2em 0.5em 1em;
    margin: 0.75em 0;
  }

  .close-notification {
    padding: 0px;
    position: absolute;
    top: 8px;
    right: 0px;
  }
}

.paypal-btn {
  border-radius: 30px;
  overflow-y: hidden;
  max-height: 34px;
}

.profile-toggle-wrapper {
  >div:last-child {
    background: var(--primary-gradient-light);
    color: unset;
  }

  a,
  button {
    display: block;
    text-align: left;
    margin-bottom: 0.5em;
  }

  hr {
    margin-left: 0px;
  }
}

.profile-toggle-button {
  width: 45px;
  height: 45px;
  padding: 0px;
  padding-top: 1px;
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-gray);
  border-color: var(--primary-gray);

  &:hover {
    background-color: var(--primary-gray);
    border-color: var(--primary-gray);
  }
}

.inline-button-input {
  .form-group {
    position: relative;
  }

  // .row {
  //   align-items: flex-end;
  //   button {
  //     margin-bottom: 7px;
  //   }
  // }
  &.profile-inline-button {
    .row {
      margin-top: 0px;
    }
  }

  .input-inline-btn {
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: 5;
  }
}

.mobile-nav-collapse-icon {
  display: none;
}

.add-button.form-group {
  position: relative;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  .addon {
    width: 100%;
    height: calc(1.5em + 0.75rem);
    background-color: #45b249;
    position: absolute;
    border-radius: 20px;
    right: 0px;
    cursor: pointer;
    min-width: 150px;

    .addon-inner {
      position: absolute;
      color: white;
      left: 35%;
      top: 30%;
      font-size: 80%;
    }
  }
}

.feature-comparison-wrapper .table-responsive {
  background: none;
  border-radius: 0px;

  thead .title-icon {
    margin-left: 0px;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  border-radius: 20px;
  background: var(--primary-gradient-light);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  th {
    color: #6c7d85;
  }

  th:hover::after {
    background: transparent;
  }

  th:hover {
    color: var(--logo-color-2);
    cursor: pointer;
  }

  .hline-grey {
    border-top: 3px solid #707070;

    td {
      padding: 2px;
    }
  }

  .hline-gradient {
    border-top: 3px solid var(--primary-gradient);

    td {
      padding: 2px;
    }
  }

  .hline-white {
    border-top: 3px solid white;

    td {
      padding: 2px;
    }
  }

  .empty-record {
    font-weight: normal;
    color: #6c7d85;
  }
}

.case-container {
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 5px;
  position: relative;
  background: #f1f9ff;

  .case-header {
    background: #45b249;
    margin: 5px 10px 5px 10px;
    border-radius: 10px;
    padding: 5px;
    color: white;
    cursor: pointer;

    .row {
      margin: 0px;
    }
  }

  .case-section {
    min-width: 100px;
    min-height: 120px;
    max-width: 150px;
    max-height: 150px;
    background: white;
    border-radius: 10px;
    margin: 5px 5px 5px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    div {
      margin-bottom: 5px;
    }

    #lock {
      text-align: right;
      width: 100%;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    #num {
      max-width: 50px;
      max-height: 50px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      background: #45b249;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #title {
      font-size: 120%;
    }

    #date {
      font-size: 60%;
    }

    // div:first-child {

    // }

    // div:nth-child(2) {

    // }
    // div:last-child {

    // }
    // div:nth-child(3) {

    // }
  }
}

.edit-button {
  padding: 0px !important;
  background: transparent;
  color: white;

  &:hover {
    color: grey;
  }
}

.td-action {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  img {
    cursor: pointer;
    margin-right: 2px;
  }

  svg {
    margin-right: 2px;
    cursor: pointer;

    &:hover {
      color: #7c7c7c;
    }
  }
}

th.rdtSwitch:hover {

  th.rdtNext::after,
  th.rdtSwitch::after {
    content: none;
  }
}

.module-top-nav {
  padding: 20px;

  // .left {
  //   > div {
  //     padding-left: 2em !important;
  //     padding-right: 2em !important;
  //   }
  // }
  .row:first-child>.col {
    display: flex;
    padding-left: 0px;

    div {
      margin-right: 10px;
      padding: 10px;
      cursor: pointer;
    }

    div.active {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: linear-gradient(90deg, #2a92cf, #45b249);
      color: white;
    }
  }

  .row:first-child>.col.right {
    flex-direction: row-reverse;
    padding: 0px;

    div {
      margin-left: 10px;
      margin-right: 0px;
      padding: 10px;
      cursor: pointer;
    }
  }

  .row:last-child>.col {
    padding-left: 0px;
    padding-right: 0px;

    .border {
      background: linear-gradient(90deg, #2a92cf, #45b249);
      min-height: 3px;
      border-radius: 0px 2px 2px 2px;
      border-color: transparent !important;
    }
  }
}

.patient-info {
  padding: 10px;
  text-align: center;

  h2 {
    margin-bottom: 5px;
  }
}

.patient-list-section {
  margin: 0px !important;

  .border {
    background: linear-gradient(90deg, #2a92cf, #45b249);
    min-height: 3px;
    border-radius: 0px 2px 2px 2px;
    border-color: transparent !important;
  }
}

.module-date-selection {
  padding: 20px;

  .row>.col {
    display: flex;
    align-items: center;
    min-height: 40px;

    // padding-left: 0px;
    div {
      margin-right: 5px;
    }
  }
}

.module-score-highlight-1 {
  width: 55%;
  background: var(--primary-gradient-semi-light);
  border-radius: 15px;
  padding: 20px 0px;
  align-items: center;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.fq-rating-button-inactive {
  background: var(--primary-gradient-reverse-semi-light);
  margin-right: 10px;
  border-radius: 50%;
  padding: 6px 10px;
  color: var(--primary-gray);
}

.fq-rating-button-active {
  background: var(--primary-gradient-reverse);
  color: white;
  margin-right: 10px;
  border-radius: 50%;
  padding: 6px 10px;
}

.fq-rating-button-inactive:hover,
.fq-rating-button-active:hover {
  background: var(--overlay-color);
}

.progress-charts-container {
  padding: 20px;

  canvas {
    border-radius: 20px;
    border-color: #cbcbcb;
    border-width: 2px;
    border-style: solid;
    padding: 20px;
    // max-width: 100%;
  }

  .row>.col {
    text-align: center;
    margin-bottom: 5vh;
  }
}

.posture-date-overlay-wrapper {
  div.popover-body {
    padding: 20px;
    min-width: 300px;
  }

  div {
    ul {
      padding-left: 0px;
      margin: 0px;
    }

    .active-date {
      background: #ffffff !important;
      color: var(--primary-green);
      width: 100%;
      text-align: left;
      position: relative;

      img {
        position: absolute;
        right: 0.75em;
        top: 14px;
      }
    }

    .white-link {
      background: transparent;
      color: white;
    }
  }
}

.zoomed-posture-modal {

  .modal-body,
  .modal-content {
    background-color: transparent;
    // border-radius: 5%;
    border: none;

    .col {
      align-items: center;
      justify-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

// .zoomed-posture-canvas {
//   font-weight: bold;
//   font-size: 48px;
// }

.posture-canvas-overlay {
  // width: 278px;
  // height: 372px;
  border-radius: 20px;
  background-color: rgba(245, 249, 255, 0.5);
  position: relative;

  .zoom-button {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.5;
    color: var(--logo-color-2);
    font-size: 120%;
    padding: 0px;
  }

  .zoom-button:hover {
    opacity: 1;
    font-size: 150%;
  }
}

.posture-individual-scan-container {
  padding: 20px;

  .row>.col:first-child {
    padding: 0px;
  }

  .row {
    margin-bottom: 10vh !important;
  }

  .posture-score {
    padding-left: 0px;
    align-items: flex-start !important;

    div {
      margin-bottom: 0.5rem;
    }

    div:first-child,
    div:last-child {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }

    .posture-score-highlight {
      width: 70%;
      background: var(--primary-gradient-semi-light);
      border-radius: 20px;
      padding: 40px 20px;
      display: flex;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-top: 20px;
    }
  }

  .posture-score-highlight {
    display: flex;
    justify-content: center;
    width: 70%;
    background: var(--primary-gradient-semi-light);
    border-radius: 20px;
    padding: 40px 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .row:nth-child(3)>.col {
    display: flex;
    justify-content: center !important;
  }
}

.journal-container {
  .posture-score-highlight {
    display: block;
    justify-content: center;
    max-width: 200px;
    background: var(--primary-gradient-semi-light);
    border-radius: 20px;
    padding: 40px 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    margin: auto;
  }
}

.dashboard-pagination {
  .btn {
    color: #707070;
  }

  .active {
    color: var(--primary-blue);
  }
}

.custom-toggle-1-container {
  display: flex;
  position: relative;
  align-items: center;

  button {
    border: inset 3px solid transparent !important;
    width: 90%;
    background-color: #6c7d85;
  }

  button:nth-child(1) {
    position: absolute;
    left: 0%;
    z-index: 0;
    text-align: left;
  }

  button:nth-child(2) {
    position: absolute;
    right: 0%;
    z-index: 1;
    text-align: right;
  }

  button:nth-child(1).active {
    z-index: 999;
    border: 3px solid white !important;
    width: 50%;
    text-align: center;
    background-color: #2a92cf !important;
  }

  button:nth-child(2).active {
    z-index: 999;
    border: 3px solid white !important;
    width: 50%;
    text-align: center;
    background-color: #2a92cf !important;
  }
}

.custom-toggle-2-container {
  display: flex;
  position: relative;
  align-items: center;

  button {
    border: inset 3px solid transparent !important;
    width: 90%;
    background-color: #7c7c7c;
    margin: 0em 12px !important;
  }

  button:nth-child(1) {
    position: absolute;
    left: 0%;
    z-index: 0;
    text-align: left;
  }

  button:nth-child(2) {
    position: absolute;
    right: 0%;
    z-index: 1;
    text-align: right;
  }

  button:nth-child(1).active {
    z-index: 999;
    border: 3px solid white !important;
    width: 50%;
    text-align: center;
    background-color: #2a92cf !important;
  }

  button:nth-child(2).active {
    z-index: 999;
    border: 3px solid white !important;
    width: 50%;
    text-align: center;
    background-color: #2a92cf !important;
  }
}

.form-container {
  input {
    background: var(--primary-gradient-light);
    border: none;
    padding: 0.25rem 1em;
  }

  label {
    margin-bottom: 0px;
    font-size: 0.8em;
    font-weight: 600;
    padding: 0 0.5em;
  }

  .invalid-feedback {
    left: 1.5em;
    bottom: -14px;
  }
}

.textarea-container {
  position: relative;
  display: flex;
  justify-content: center;

  textarea {
    width: 60%;
  }

  span {
    position: absolute;
    right: 22%;
    bottom: 20px;
    color: var(--logo-color-2);
    font-weight: bolder;
    // background-color: #cbcbcb;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.textarea-container-2 {
  position: relative;
  display: flex;
  justify-content: center;

  textarea {
    width: 100%;
  }

  span {
    position: absolute;
    right: 22%;
    bottom: 20px;
    color: var(--logo-color-2);
    font-weight: bolder;
    // background-color: #cbcbcb;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

canvas {
  font-family: "Rubik", sans-serif !important;
}

input[type="range"] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #007904;
  cursor: pointer;
  margin-top: -9px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
/* All the same stuff for IE */
input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #007904;
  cursor: pointer;
  // margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #007904;
  // border-radius: 1.3px;
  border: none;
  opacity: 0.5;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]:focus::-webkit-slider-runnable-track,
input[type="range"]:hover::-webkit-slider-runnable-track {
  opacity: 1;
}

.pain-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  span {
    margin-right: 20px;
  }

  span:nth-child(1),
  span:nth-child(3) {
    font-weight: 500;
    line-height: 1.2;
    color: var(--primary-gray);
  }

  span:nth-child(3) {
    display: inline-flex;
    background: #707070;
    color: white;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  span:nth-child(4) {
    display: inline-flex;
    background: var(--primary-green);
    color: white;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }

  span:nth-child(2)>input {
    min-width: 15vw;
  }

  // .pain-slider {
  //   -webkit-appearance: none;
  //   width: 100%;
  //   height: 5px;
  //   background: #d3d3d3;
  //   outline: none;
  //   opacity: 0.7;
  //   -webkit-transition: 0.2s;
  //   transition: opacity 0.2s;
  // }

  // .pain-slider:hover {
  //   opacity: 1;
  // }
}

.dashboard-main-analytics-card {
  max-width: 300px;
  min-height: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding-top: 5px;
}

.sync-rotation {
  svg {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Tablet

@media (max-width: 1224px) {
  .alert:not(.main-alert) {
    // left: calc(-1.5em - 230px);
  }

  .side-nav {
    width: 250px;

    >img:first-child {
      width: 180px;
    }
  }

  .application-wrapper {
    main {
      padding: 5em 1.5em;
      max-width: 70%;
      min-width: unset;
    }

    .license-card {
      padding: 1.5em 1.5em;
      flex-wrap: wrap;

      // flex-direction: column;
      ul {
        width: 80% !important;
        font-size: 15px;
      }

      .license-card-cta-wrapper {
        margin-top: 1em;
        width: 100% !important;
      }

      .license-card-name-wrapper {
        min-height: 100%;
      }
    }

    h4 {
      font-size: 18px;
    }
  }

  .exit-link {
    left: 2.5em;
  }

  .side-nav-collapse-icon {
    display: none;
  }

  .info-details p,
  .info-details button {
    font-size: 0.7em;
    line-height: 1.2em;
    text-align: left;

    span {
      display: block;
    }
  }

  .mobile-nav-collapse-icon {
    display: none;
  }
}

@media (max-width: 37.5em) {
  .alert:not(.main-alert) {
    // left: calc(-1em - 0px);
    // z-index: 20;
  }

  .main-alert {
    display: none;
  }

  .side-nav {
    position: absolute;
    z-index: 5;
    overflow: visible;
    min-height: 100%;
    position: fixed;

    width: 220px;

    >img:first-child {
      width: 180px;
    }

    .mobile-nav-collapse-icon {
      display: block;
      // height: 60px;
      position: absolute;
      top: 3em;
      right: -30px;
    }

    .side-nav-button.green-color.active-sn {
      width: auto;
    }

    &.mobile-active {
      // position: absolute;
      left: -56%;
      // height: 100%;
    }
  }

  .role-section-wrapper {
    // display: block;
    flex-wrap: wrap;

    >div:last-child {
      margin-top: 2em;
    }
  }

  .overview-staff-wrapper {
    >div.row {
      h4 {
        margin-bottom: 0px;
      }

      button {
        margin-top: -25px;
      }

      margin-bottom: 0.5em;
    }
  }

  .top-nav-wrapper {
    top: 0.5em;
    right: 1em;

    p {
      width: 100%;
      padding: 0 1.5em;
      text-align: center;
      margin-right: 0px;
    }

    .help-button {
      margin-right: 1em;
    }
  }

  .notification-icon-wrapper {
    display: none;
  }

  .application-wrapper {
    main {
      padding: 5em 1em;
      padding-top: 7em;
      max-width: 100%;

      .exit-link {
        display: none;
      }
    }

    .license-form-wrapper {
      h4 {
        text-align: center;
        margin-bottom: 1em !important;
      }

      .table-cta-top {
        justify-content: center;
      }
    }

    .license-card {
      padding: 1.5em 1.5em;
      flex-wrap: wrap;

      // flex-direction: column;
      ul {
        width: 80% !important;
        font-size: 15px;
      }

      .license-card-cta-wrapper {
        margin-top: 1em;
        width: 100% !important;
      }

      .license-card-name-wrapper {
        min-height: 100%;
      }
    }

    h4 {
      font-size: 18px;
    }
  }

  .profile-inline-button {
    button {
      position: absolute;
      right: 0em;
      transform: scale(0.7);
    }
  }

  .create-org-wrapper {

    .row,
    .row div {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }

    input,
    select {
      margin-bottom: 1em !important;
      margin-top: 0 !important;
    }

    .form-footer-wrapper {
      button {
        margin-top: 2em;
      }
    }
  }
}