#paypal-button { 
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;

    .col {
        width: 100%;
        text-align: center;
    }

    .paypal-button {
        display: none !important;
    }
}

#checkout {
    color: var(--primary-gray);
    padding: 4em 0;

    .empty-cart-icon {
        margin: 25px 0;
        max-width: 80px;
        max-height: 80px;
    }

    div.row {
        width: 100%;
    }

    div.col {
        text-align: center;
    }
    h1 {
        margin-bottom: 1em;
    }
    h3 {
        margin-top: 1em;
    }

} 


.payment-success-container {
    padding: 5%;

    .row {
        justify-content: center;
        align-items: center;
    }

}

.show-cart-container {

    > .row {
        justify-content: center;
        align-items: center;
        margin: 5vh 0;

        > .col {
            max-width: 600px;
            width: 80vw;
            text-align: center;
        }
    }

    h3 {
        margin: 2%;
    }
}

.cart-query-600 {
    background-color: #f7f9fc;
    border-radius: 5px;
    font-size: 75%;
    margin: 10px 0;
    padding: 2em .5em;

    .cart-query-align-left {
        text-align: left;
        margin: auto;
    }
    .cart-query-align-right {
        text-align: right;

        span {
            font-weight: bold;
        }

        button {
            transform: scale(.7);
            margin-right: -20px;
        }
    }

}

.cart-query-599 {
    background-color: #f7f9fc;
    border-radius: 5px;
    font-size: 75%;
    margin-top: 10px;

    .cart-query-col1 {
        text-align: left;
        margin: auto;
    }
    .cart-query-col2 {
        text-align: left;

        span {
            font-weight: bold;
        }

        button {
            background-color: #ffd6d6;
        }
    }
}


