.standard-hero-wrapper {

  background: url('../photos/default-hero-bg.png');
  background-size: cover;
  background-position: bottom;
  position: relative;
  padding: 6em 0;
  .row {
    margin: 0px;
  }
  .row, .col {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      margin-bottom: .6em;
    }
  }

  &.more-padding {
    padding: 12em 0;
  }

  .bg-image, .bg-image-grad-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bottom-clip {
    position: absolute;
    bottom: -1px;
  }
}



// Tablet

@media (max-width: 1224px) {

  .standard-hero-wrapper.more-padding {
    padding: 7em 0;
  }



}



// Mobile

@media (max-width: 767px) {

  .standard-hero-wrapper {
    background-position: 60%;

    &.more-padding {
      padding: 5em 0;
    }
  }



}