.about-cta-wrapper {
  background: url('../photos/grad-rule-concave.svg');
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 7.5em;

}

.timeline-wrapper {
  padding-top: 3em;

  .timeline-item-title {
    color: var(--primary-blue);
    background: #eaf4f9;
    padding: 10px 20px;
    border-radius: 20px;
  }
  .timeline-item-title.active {
    color: #ffffff !important;
  }
  .card-title {
    font-size: 1.2em;
    margin-left: -2px;
  }
  .timeline-card-content {
    padding: 1.5em 2em;
    border-radius: 1em;
    filter: none;
  }
  .timeline-card-content.active {
    background: linear-gradient(90deg, rgba(42,147,208,1) 0%, rgba(69,179,73,1) 100%);
    p {
      color: #ffffff;
    }
  }
  .card-sub-title {
    color: var(--primary-gray);
    font-weight: 400;
    font-size: .8em;
    line-height: 1.2em;
    margin-top: 10px; 
  }
  .timeline-controls {
    display: none;
  }
}

// Desktop

@media (min-width: 1224px) {
  .mobile-timeline {
    display: none;
  }
  .desktop-timeline {
    display: flex;
  }
}


// Tablet

@media (max-width: 1224px) {


  .about-application-wrapper {
    flex-direction: column-reverse;
  }

  .about-supporters-wrapper {

    .title-icon {
      justify-content: center;
      align-items: center;
    }

  }

  .mobile-timeline {
    display: flex;
  }
  .desktop-timeline {
    display: none;
  }

  .about-cta-wrapper {
    padding-top: 5em;
  }


}


@media (max-width: 767px) { 


  .mobile-timeline {
    padding-top: 3em;

    .timeline-item-title {
      color: var(--primary-blue);
      background: none;
      padding: 0px;
      border-radius: 0px;
    }
    .timeline-item-title.active {
      color: #2a93d0 !important;
      background: none;
    }
    .card-title {
      font-size: 1.2em;
      margin-left: -2px;
      line-height: 1em;
    }
    .vertical {
      padding: 0px;
      > div {
        padding: 0px;
      }
    }
    .timeline-card-content {
      padding: .5em .75em;
      border-radius: 1em;
      filter: none;
    }
    .timeline-card-content.active {
      background: linear-gradient(90deg, rgba(42,147,208,1) 0%, rgba(69,179,73,1) 100%);
      p {
        color: #ffffff;
      }
    }
    .card-sub-title {
      color: var(--primary-gray);
      font-weight: 400;
      font-size: .8em;
      line-height: 1.2em;
      margin-top: 10px; 
    }
    .timeline-controls {
      display: none;
    }
    .vertical-item-row > div:first-child {
      width: 30%;
    }
  }



}