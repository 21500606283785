/* Adding Custom Font */
/* @font-face {
    font-family: "Barlow-Condensed";
    src: url("resources/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf");
    src:
    url("resources/fonts/Barlow_Condensed/barlowcondensed-regular-webfont.woff") format("woff"),
    url("resources/fonts/Barlow_Condensed/barlowcondensed-regular-webfont.woff2") format("woff2"),
    url("resources/fonts/Barlow_Condensed/barlowcondensed-regular-webfont.svg") format("svg")
} */

:root {
  --primary-gray: #6c7d85;
  --primary-gray-light: #f1f2f3;
  --primary-blue: #2a93d0;
  --primary-green: #45b349;
  --primary-red: #CB190B;
  --primary-gradient: linear-gradient(90deg, rgba(42, 147, 208, 1) 0%, rgba(69, 179, 73, 1) 100%);
  --primary-gradient-reverse: linear-gradient(90deg, rgba(69, 179, 73, 1) 0%, rgba(42, 147, 208, 1) 100%);
  --primary-gradient-light: linear-gradient(90deg, rgba(234, 244, 250, 1) 0%, rgba(237, 247, 237, 1) 100%);
  --primary-gradient-reverse-light: linear-gradient(90deg, rgba(237, 247, 237, 1) 0%, rgba(234, 244, 250, 1) 100%);
  --primary-gradient-semi-light: linear-gradient(90deg, rgba(215, 235, 242, 1) 0%, rgba(219, 239, 223, 1) 100%);
  --primary-gradient-reverse-semi-light: linear-gradient(90deg, rgba(219, 239, 223, 1) 0%, rgba(215, 235, 242, 1) 100%);
  --primary-gradient-footer: linear-gradient(90deg, rgba(69, 179, 73, 0.46) 0%, rgba(55, 163, 142, 0.53) 51%, rgba(42, 147, 208, 1) 100%);

  --primary-color: rgb(255, 255, 255);
  --secondary-color: #000;
  --overlay-color: rgba(0, 0, 0, 0.5);
  --logo-color-1: #2994d1;
  --logo-color-2: #44b549;
  --background-color-1: #335c67;
  --background-color-2: #fdcd42;
  --background-color-white: white;
  --background-color-dark-light: #4d4d4d;
  --background-color-gray: #b8b8b8;
  --background-color-linear-gradient: linear-gradient(to bottom, #c5dafd 0%, #dceff8 100%);
  --button-color-1: #6ee0cf;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} */

html,
body {
  height: 100%;
  font-family: "Rubik", sans-serif;
  line-height: 1em;
  font-size: 1rem;
}

h1 {
  font-size: 46px;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  line-height: 1em;
}

h2 {
  font-size: 36px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
}

h3 {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
}

p {
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.6em;
  color: var(--primary-gray);
}

blockquote {
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

pre {
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100h;
  max-height: 100v;
}

.two-buttons {
  display: flex;
}

.two-buttons > button:first-child {
  margin-right: 1em;
}
.hidden {
  display: none;
}

.btn {
  border-radius: 40px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding: 0.6em 1.5em;
  transition: all 200ms ease;
  font-weight: 500;
  line-height: 1em;
}

.btn-secondary {
  background: var(--primary-green);
  border: none;
  border-radius: 50px;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.btn-primary {
  background: var(--primary-blue);
  border: none;
  border-radius: 50px;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.btn-secondary-2 {
  background: var(--primary-gray);
  border: none;
  border-radius: 50px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: white;
}

.btn-dark {
  background: var(--primary-gray);
  border: none;
  border-radius: 50px;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.btn-dark:hover {
  background: #515e64;
}
.btn-primary:hover {
  background: var(--primary-blue);
  filter: contrast(1.4);
}

.btn-secondary:hover:not(:disabled) {
  background: var(--primary-green);
  filter: contrast(1.4);
}

.btn-secondary-2:hover:not(:disabled) {
  background: var(--primary-gray);
  filter: contrast(1.4);
  color: white;
}

.btn-outline-light,
.btn-outline-secondary,
.btn-outline-primary,
.btn-outline-danger,
.btn-outline-dark {
  border-radius: 30px;
  border-width: 4px;

  padding: 0.18em 1.1em;
  line-height: 1.3em;
}

.btn-outline-dark {
  border-color: var(--primary-gray);
  color: var(--primary-gray);
}

.btn-outline-dark:hover {
  background-color: var(--primary-gray);
  border-color: var(--primary-gray);
}

.btn-outline-light:hover {
  color: var(--primary-blue);
}

.btn-outline-primary {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.btn-outline-primary:hover {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
  filter: contrast(1.4);
}

.btn-outline-secondary {
  border-color: var(--primary-green);
  color: var(--primary-green);
}

.btn-outline-secondary:hover {
  background-color: var(--primary-green);
  border-color: var(--primary-green);
  filter: contrast(1.4);
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link-secondary {
  color: var(--primary-green);
}
.btn-link-secondary:hover {
  color: var(--primary-green);
  filter: contrast(1.4);
}
.btn.muted:hover {
  opacity: 1;
}
.no-padding {
  padding: 0px !important;
}
.no-vertical-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.no-horizontal-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hr-style-one {
  display: block;
  height: 1px;
  border: 0;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 10px auto;
  padding: 0;
  max-width: 50%;
}

.hr-style-two {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px auto;
  padding: 0;
  max-width: 50%;
}

.hr-style-three {
  width: "100%";
  max-width: "100%";
}

.hr-style-four {
  margin: 0.6em 0 1em 0;
}

hr {
  display: block;
  border: none;
  height: 4px;
  background: var(--primary-gradient);
  border-radius: 10px;
  width: 80px;
}

/* Utilities */

/* .btn{
    display: inline-block;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.8rem 1.5rem;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    border-radius: 20px;
}

.btn:hover{
    background-color: var(--secondary-color);
} */

.btn-purchase {
  display: inline-block;
  background-color: var(--logo-color-2);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 20px;
  /* width: 100%; */
  margin: 5%;
}

.btn-purchase:hover {
  background-color: green;
  animation-duration: 1s;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--overlay-color);
  /* background-color: red; */
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  background: white;
  z-index: 999;
}