#main-navbar {
  padding: 1.4em 3.15em;
  background: #ffffff;
  transition: all 100ms ease;

  #dropdown-btn {
    a {
      padding-left: 1em;
    }

    div {
      padding-left: 0.5em;
    }
  }

  img {
    width: 330px;
    transition: all 100ms ease;
  }

  div[class*="col-"] {
    height: auto;
    width: auto;
  }

  svg,
  .cart-icon {
    width: 24px;
    height: 24px;
  }

  a {
    color: var(--primary-gray);
    font-weight: 500;
    transition: all 200ms ease;

    &:hover {
      color: var(--primary-blue);
    }
  }
  a.is-active {
    color: var(--primary-blue);
  }

  .professionals-nav-hr {
    display: none;
  }

  #dropdown,
  #pro-dropdown {
    &:after {
      transform: translateY(3px);
    }
  }

  #dropdown-btn {
    background: var(--primary-blue);
    border-radius: 50px;
    padding: 12px 1.5em;
    margin: -2px 0.5em 0 0.5em;
    color: #ffffff;
    transition: all 200ms ease;

    &:after {
      transform: translateY(3px);
    }

    &:hover {
      filter: contrast(1.4);
    }
  }

  .dropdown-menu.show {
    border-radius: 12px;
    padding: 1em 0em;
    border: none;
    background: var(--primary-gray-light);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: var(--primary-gray-light);
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background: #f8f9fa;
  }

  .nav-hr {
    border-radius: 10px;
    margin: 0px;
    width: 300px;
    height: 4px;
    border: none;
    background: var(--primary-gradient);
    bottom: -2px;
    position: absolute;
    right: 3.15em;
    transition: all 1400ms ease;
  }
  .consumer-download-button,
  #consumer-download-button {
    background: var(--primary-blue);
    border-radius: 50px;
    padding: 0.2em 1.5em;
    margin: 0 0.5em;
    color: #ffffff;
    transition: all 200ms ease;
    margin-bottom: 0px;
    line-height: 2em;
    margin-top: 0px;
    font-size: 15px;

    // &:after {
    //     transform: translateY(3px);
    // }

    &:hover {
      filter: contrast(1.4);
      color: #ffffff;
    }
  }

  .dropdown {
    a a {
      padding-left: 0px;
    }
  }
}

.portal-nav-links-wrapper {
  transition: all 200ms ease;
  transform: scale(0.9);
  position: fixed;
  top: 28px;
  right: 3.5em;
  z-index: 30000000000;
  span {
    font-size: 12px;
    font-weight: 800;
  }
  a:hover {
    color: var(--primary-blue) !important;
  }
}

div.dropdown-menu {
  font-size: 80%;
}

.dropdown:hover .dropdown-menu {
  display: block;
  transform: translateY(-5px);
}

#banner {
  background-color: #deffde;
  color: black;

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: #47a2da;
    }
  }

  div[class^="col"] {
    text-align: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}

.mobile-portal-nav-links {
  display: none;
}

// smaller laptops

@media (max-width: 1255px) and (min-width: 992px) {
  .navbar-nav {
    a {
      font-size: 0.8em;
    }
  }

  .navbar-brand {
    transform: scale(0.6);
    margin-left: -2.75em;
  }
}

// tablets
@media (max-width: 768px) {
  // #main-navbar {
  //     padding: 1.4em 3.15em;
  //     background: #ffffff;
  //     transition: all 100ms ease;

  //     .navbar-brand {
  //         transform: scale(.7);
  //     }
  // }

  .portal-nav-links-wrapper {
    display: none;
  }

  .mobile-portal-nav-links {
    display: flex;
    flex-direction: column;
    padding-top: 0.5em;
    a:first-child {
      margin-bottom: 0.5em;
    }
  }

  .navbar-nav {
    text-align: center;
    padding: 1em 0;
    font-size: 1.2em;
    line-height: 1.2em;

    .dropdown-menu.show {
      text-align: center;
      background: none !important;
    }

    button {
      border-radius: 10px;
      &:focus {
        outline: none !important;
      }
    }
  }

  #dropdown-btn {
    display: inline-block;
    // margin-bottom: 20px !important;
  }
}

// mobile

@media (max-width: 767px) {
  .mobile-portal-nav-links {
    display: none;
  }

  #main-navbar {
    padding: 0.5em 1em !important;

    hr {
      right: 1em !important;
      width: 60% !important;
    }
  }

  // .navbar-nav > a, .navbar-nav > div {
  //     padding-left: 0px !important;
  // }

  .navbar-brand {
    width: 75%;
    img {
      width: 100% !important;
    }
  }
}
