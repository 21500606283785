#blogheader {
    color: white;
    background-image: url('../photos/blog/background.jpg');
    background-position: center;
    background-size: cover;
    min-height: 50vh;

    div.row{
        min-height: 50vh;
        text-align: center;
        align-items: center;
    }
}

#blogbody {
    min-height: 10vh;
    margin-top: 2vh;
    margin-bottom: 5em;

    div[class^="col"]{
        margin-bottom: 2%;
    }

    .row {
        align-items: flex-start;
    }
}

#blog  {
    border: none;
    overflow: hidden;
    animation: appear 1.5s;
    margin-top: 20px;

    > a {
        &:hover img {
            filter: brightness(1);
            // transform: scale(1.1);
            width: 110%;
        }        
        img{
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 10px 0;
            border-radius: 13px;
            object-fit: cover;
            transition: all 400ms ease;
            filter: brightness(.8);
        }
    } 

}

#blog-author-description{
    margin: 2%;
    align-content: center;
    align-items: center;
    font-size: 60%;
    font-weight: bold;

    div[class^="col"]{
        justify-content: left;
        text-align: left;
        padding: 0px 0px;

        &:nth-child(2) {
            padding-left: 15px;
        }

        &:last-child{
            text-align: right;
            justify-content: left;
        }
    }

    img {
        max-height: 48px;
        height: 100%;
        width: auto;
    }

}

#blog-author-name {
    width: 100%;
    text-align: right;
    margin-right: 10px;

    a {
        text-decoration: none;
        color: black;

        &:hover {
            color: #3C7944;
        }
    }

    img{
        max-height: 18px;
        height: 100%;
        width: auto;
        display: inline;
    }
}

#blog-post-time {
    width: 100%;
    text-align: right;

    img {
        display: inline;
    }
}

#blog-share img{
    max-height: 24px;
    display: inline;
}

#blog-title {

    div[class^="col"]{
        margin-top: 12px;
        margin-bottom: 5px;
        font-weight: bolder;
    }

    h3 {
        margin: 0px;
        transition: all 200ms ease;
    }

    a {
        

        &:hover h3 {
            filter: contrast(1.4) !important;
            cursor: pointer;
        }
    }

}

#blog-subtitle {
    font-size: 80%;

    div[class^="col"]{
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: bolder;
    }

    a{
        text-decoration: none;
        color: black;
        line-height: 15px;
        display: inline-block;

        &:hover {
            color: #3C7944;
        }
    }

}

#blog-footer{
    padding-top: 2%;
    margin: 5px 0px 5px 0px;
    button {
        margin-top: -10px;
    }
}


#blog-main {
    align-content: center;
    align-items: center;
    padding: 5em 0;
    overflow: hidden;
    animation: fade 2s;
}

#blog-main-nav {
    min-height: 10vh;

    h3 {
        display: inline;
    }

    div[class^="col*"]{
        align-items: center;
        align-content: center;
    }

    a {
        color: #000000;
    }

    a:hover{
        text-decoration: none;
        cursor: pointer;
        filter: contrast(1.4);
    }
}

.back-button-wrapper {

    a:hover {
      filter: contrast(1.4);  
    }

    a img {
        margin-top: -5px;
    }
}

.author-description-wrapper {
    .row {
        align-items: flex-end;

       .col {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px;
            position: relative;

            p {
                margin-bottom: 0px;
                line-height: 1.3em;
                color: var(--primary-blue);
                font-size: 1em;
                font-weight: 600;

                span {
                    font-size: .8em;
                    margin-top: -5px;
                    display: inline-block;
                    font-weight: 200;
                }
            }

            img {
                margin-left: 1em;
            }
            hr {
                position: absolute;
                bottom: -2em;
                right: 0px;
            }
        }
    }
}


/* Main Blog CSS */

#blog-main-body {
    animation: appear 1.5s;

    *{
        font-family: 'Roboto', sans-serif;
        font-size: 1.1rem;
    }

    .col {
        p {
            text-align: left;
        }
    }

    .main-body-col {
        text-align: center;
        margin: 3em 0 2vh 0;  
    }

    h1 {
        font-size: 36px;
    }

    p {
        margin-top: 5%;
        margin-bottom: 5%;
        line-height: 1.7em;

        img {
            justify-self: center;
        }
    }

    code {
        width: 100%;
    }

    a {
        color: var(--primary-blue);
    }

    figure {
        text-align: center;
    }

    img {
        max-width: 100%;
        width: 100%;
        height: auto;

        :first-child{
            max-height: 100%;
        }
    }

    pre {
        background-color: #b0ffb5;
    }

    div[class^="col"] {
        text-align: left;
    }

    div[class^="row"]:first-child div[class^="col"]{
        text-align: center;
    }

    figcaption, code {
        width: 100%;
    }

    figcaption {
        text-align: center;
        font-weight: bold;
    }

    h3, h4, h5, h6 {
        font-weight: bold;
        font-size: larger;
        color: var(--primary-gray);
    }
}

.social-media-wrapper {
    align-items: center;
    justify-content: center;
    padding: 2em 0 3.5em 0;
}

.social-media {
    margin: 5px;
    padding: 0 0;
    max-width: 60px;

    div {
        background-color: var(--primary-blue);
        text-align: center;
        border-radius: 50%;
        border: 3px solid var(--primary-blue);
        width: 60px;
        height: 60px;
        z-index: 1;
        cursor: pointer;
        overflow: hidden !important;

        &:hover a::before{
            top: -100%;
        }
    }

    a {
        top: 30%;
        position: relative;
        z-index: 3;
        color: #ffffff;

        &:before {
            content: "";
            position: absolute;
            top: 200%;
            left: -200%;
            width: 500%;
            height: 300%;
            background: #ffffff;
            transition: .5s;
            z-index: -1;
        }
    }

    a svg {
        transition: .5s;
        
    }

    div:hover a svg {
        transform: rotateY(360deg);
        color: #fff;
    }

}

#social-facebook a::before{
    background-color: #4267B2;
}
#social-twitter a::before{
    background-color: #1DA1F2;
}
#social-linkedin a::before{
    background-color: #016197;
}

/* animations */
@keyframes appear {
    from {max-height: 0%;}
    to {max-height: 100%;}
}

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

.comments-wrapper {
    input, textarea {
        margin-top: 1em;
        border-radius: 20px;
        border: none;
    }
    form {
        text-align: right;
    }
}

.blog-cta-wrapper {
    padding: 0px;

    a {
        margin-top: .6em;
    
    }

}



// Tablet

@media (max-width:1224px) {

    #blog {
        margin-bottom: 1em;
        
        #blog-title .col, #blog-subtitle .col, #blog-footer {
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

}




// Mobile

@media (max-width:767px) {

    #blog-main {
        padding: 3em 0;
    }


    #blog-main-nav {

        > .back-button-wrapper {
            max-width: 36% !important; 
            // transform: scale(.8);
        }

        .author-description-wrapper {
            max-width: 63% !important; 
            // transform: scale(.8);
            #blog-author-name {
                font-size: 15px;
                line-height: 1em !important;
            }

            .row .col p#blog-post-time {
                font-size: 12px;
                line-height: 1em !important;
            }
            img {
                max-width: 40px;
            }
        }

    }

    .blog-cta-wrapper {
        margin-top: -2em;
        hr {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .social-media-wrapper {
        padding-bottom: 0em;
    }



}

