#application-background {
    /* background-color: aquamarine; */
    height: 50vh;
    background-image: url('../photos/applications/app_background_1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
}

#application-background div.row {
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

#application-background div.col {
    text-align: center;
    color: white;
}

#application-health div.row{
    padding: 5%;
}

#application-health img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}

#application-health div.row{
    background-image: url('../photos/applications/health_transition.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat; 
}

#application-performance div.row{
    padding: 5%;
}

#application-performance img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}

#application-performance div.row{
    background-image: url('../photos/applications/performance_transition.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; 
}


#application-ohs div.row{
    padding: 5%;
    background-color: #f7f7f7;
}

#application-ohs img{
    border-radius: 30px;
    box-shadow: 10px 10px 5px #aaaaaa;
    width: 100%;
    height: auto;
}


@media only screen and (max-width: 768px) {
    #application-ohs div.row{
        flex-direction: column-reverse;
    }
}